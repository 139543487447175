import { invokeApi } from 'src/utils';

export const GetDashboardData = async () => {
  const requestObj = {
    path: `/api/saloon/saloon_dashboard_api`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
