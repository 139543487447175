// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
// utils
import { fShortenNumber } from "src/utils";
// components
import Iconify from "../../../components/Iconify";
import { textAlign } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ModeIcon from "@mui/icons-material/Mode";
import { useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { CleaningServices, DisabledByDefault } from "@mui/icons-material";
import { EditSchedule } from "src/DAL/Schedule";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import EditScheduleDialog from "./EditScheduleDialog";
import CloseDialog from "./CloseDialog";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";/

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

// SummaryCard.propTypes = {
//   color: PropTypes.string,
//   icon: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   count: PropTypes.number.isRequired,
//   sx: PropTypes.object,
// };

export default function DayCard({
  id,
  day,
  start_time,
  end_time,
  shop_status,
  render,
  setRender,
  setIsPending,
  ...other
}) {
  // --------------------------------------------------

  const [myShop_Status, setMyShop_Status] = useState(shop_status);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // --------------------------------------------------

  const handleEditSchedule = async () => {
    const formData = new FormData();

    formData.append("start_time", start_time);
    formData.append("end_time", end_time);
    formData.append("shop_status", myShop_Status);

    await EditSchedule(id, formData);
    {
      render === true ? setRender(false) : setRender(true);
    }
  };
  // close dialog start
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleShopStatus = () => {



    myShop_Status.toUpperCase() === "OPEN" ? setOpen(true) : handleChangeCardStatus()

  };

  useEffect(() => {
    if (myShop_Status != shop_status) {
      handleEditSchedule();
    }
  }, [myShop_Status]);

  const handleChangeCardStatus = () => {
    handleClose()
    setIsPending(true)

    myShop_Status.toUpperCase() === "OPEN"
      ? setMyShop_Status("Close")
      : setMyShop_Status("Open");


  }
  return (
    <>

      <CloseDialog
        open={open}
        setOpen={setOpen}
        title="Do you really want to close?"
        ActionButton1="No"
        ActionButton2="Yes"
        handleActionButton1={handleClose}
        handleActionButton2={handleChangeCardStatus}
      />

      <EditScheduleDialog
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog} //?
        start_time={start_time}
        end_time={end_time}
        day={day}
        myShop_Status={myShop_Status}
        id={id}
        render={render}
        setRender={setRender}
        setIsPending={setIsPending}
      />




      <Card
        sx={{
          py: 2,
          boxShadow: 5,
          textAlign: "left",
          color: (theme) => theme.palette.grey[800],
          bgcolor: "#fff",
          height: 219,
          borderRadius: "3px",
        }}
        {...other}
      >
        <Grid
          container
          rowSpacing={1}
          sx={{ px: 2, display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {day}
          </Typography>
          <IconButton
            aria-label="editscheduletime"
            disabled={myShop_Status.toUpperCase() === "OPEN" ? false : true}
            onClick={() => setOpenEditDialog(true)}
          >
            <ModeIcon sx={{ width: 15, height: 15 }} />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card
              item
              xs={6}
              sx={{
                borderRadius: 0,
                pointerEvents: "none",
                m: 0,
                width: "100%",
                boxShadow: 0,
              }}
            >
              <TimePicker
                label="From"
                disabled={myShop_Status.toUpperCase() === "OPEN" ? false : true}
                value={dayjs(start_time, "HH:mm")}
                disableOpenPicker={true}
                renderInput={(params) => (
                  <TextField fullWidth sx={{ mt: 1 }} {...params} />
                )}
              />

              <TimePicker
                label="To"
                disabled={myShop_Status.toUpperCase() === "OPEN" ? false : true}
                value={dayjs(end_time, "HH:mm")}
                disableOpenPicker={true}
                renderInput={(params) => (
                  <TextField sx={{ mt: 2.5 }} fullWidth {...params} />
                )}
              />
            </Card>
          </LocalizationProvider>
          <FormControlLabel
            control={
              <Checkbox
                checked={myShop_Status.toUpperCase() === "OPEN" ? false : true}
                onChange={handleShopStatus}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
              />
            }
            label="is closed"
            labelPlacement="end"
            sx={{ mt: 0.5 }}
          />
        </Grid>
      </Card>
    </>
  );
}
