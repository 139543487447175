import * as React from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { s3baseUrl } from "src/config/config";
import "./index.css";
import { useEffect, useState } from "react";
import { PhotoCamera } from "@mui/icons-material";
import { GetSubCategory } from "src/DAL/SubCategory";
import { UploadServiceImage, EditServiceAPI } from "src/DAL/Services";
import { GetTreatmentQuestions } from "src/DAL/TreatmentQuestions";
// import Box from '@mui/material/Box';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const Img = styled("img")({
//   //   margin: "auto",
//   display: "block",
//   maxWidth: "100%",
//   maxHeight: "100%",
// });

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const activeStatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "In-Active",
  },
];

export default function EditService({
  data,
  openEditDialog,
  setOpenEditDialog,
  setIsPending,
  drawerCondition,
  setDrawerCondition,
  setData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [listofCategory, setListofCategory] = useState();
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("");
  const [status, setStatus] = useState(true);
  const [topTrending, setTopTrending] = useState(false);
  const [category, setCategory] = useState("");
  const [newPrice, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");
  // const [image, setImage] = useState();
  const [image, setImage] = useState([]);
  const [newImagePreview, setNewImagePreview] = useState([]);
  const [newImageArray, setNewImageArray] = useState([]);
  const [imglist, setImglist] = useState([]);
  const [treatmentQuestions, setTreatmentQuestions] = useState(); //save all treatment questions
  const [selectedTreatmentQuestions, setSelectedTreatmentQuestions] = useState([]);
  const [treatmentQuestionList, setTreatmentQuestionList] = useState([]);
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpenEditDialog(true);
  // };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const uploadData = async () => {
    let SelectedQuestionObject = []
    treatmentQuestionList.map(questionTitle => {
      console.log("treatmentQuestions",treatmentQuestions)
      console.log("questionTitle",questionTitle)
      treatmentQuestions.map(questions => {
        if (questionTitle == questions.statement) {
          SelectedQuestionObject.push(questions)
        }
      })
    })
    console.log(SelectedQuestionObject,"SelectedQuestionObjectSelectedQuestionObject")
    let result = await EditServiceAPI(id, {
      title: title,
      description: description,
      category: category,
      price: newPrice,
      time: time,
      order: order,
      status: status,
      top_trending: topTrending,
      image: image,
      service_questions: [...SelectedQuestionObject],
    });
    if (result.code == 200) {
      handleClose();
      enqueueSnackbar("Treatment updated successfully", { variant: "success" })
    }
    else {
      enqueueSnackbar("Failed", { variant: "error" })
    }
  };

  useEffect(() => {
    if (imglist.length == newImageArray.length && imglist.length != 0) {
      uploadData();
    }
  }, [imglist]);

  const handleEditData = async (e) => {
    e.preventDefault();
    setOpenEditDialog(false);

    setIsPending(true);
    if (newImageArray.length != 0) {
      newImageArray.map(async (img) => {
        let formdata = new FormData();
        formdata.append("image", img);
        let result = await UploadServiceImage(formdata);
        if (result.code == 200) {
          imglist.push(result.path);
          image.push(result.path);
          setImglist([...imglist]);
          setImage([...image]);
          enqueueSnackbar("Treatment updated successfully!", { variant: "success" })
        }
        else {
          enqueueSnackbar(result.message, { variant: "error" })
        }
      });
    } else {
      uploadData();
    }
  };

  const handleNewImage = async (e) => {
    let newImage = e.target.files;

    let imagesList = Array.from(newImage);
    setNewImageArray([...imagesList]);
    // console.log(imagesList, "imagePreview");
    newImage = null;
    const imagesArray = imagesList.map((file) => {
      return URL.createObjectURL(file);
    });

    setNewImagePreview((p) => p.concat(imagesArray));
  };
  const deleteHandler = (e, img, index) => {
    e.preventDefault();
    image.splice(index, 1);
    setImage([...image]);
  };

  const newImageDeleteHandler = (e, img, index) => {
    e.preventDefault();
    newImagePreview.splice(index, 1);
    setNewImagePreview([...newImagePreview]);
    newImageArray.splice(index, 1);
  };

  const handleClose = () => {
    {
      drawerCondition ? setDrawerCondition(false) : setDrawerCondition(true);
    }
    setOpenEditDialog(false);
    setNewImagePreview([]);
    setImglist([]);
    setNewImageArray([]);
  };

  const handleChange = (event) => {
    const { target: { value } } = event;
    setTreatmentQuestionList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    console.log("medmed", treatmentQuestionList)
  };

  useEffect(() => {
    getTreatmentQuestionData();
  }, []);

  const getTreatmentQuestionData = async () => {
    try {
      const result = await GetTreatmentQuestions();
      if (result.code == 200) {

        setTreatmentQuestions(result.data.service_questions);
        console.log(result.data.service_questions, 'all service question fetched');

      } else {
        console.log(result, 'error');
      }
    } catch (error) {
      console.log(error, "catch");
    }
  }

  useEffect(() => {
    if (data) {
      setId(data._id);
      setOrder(data.order);
      setTitle(data.title);
      setCategory(data.category._id);
      setStatus(data.status);
      setTopTrending(data.top_trending)
      setDescription(data.description);
      setPrice(data.price.toString());
      setTime(data.time);
      setImage(data.image);
      setNewImagePreview([]);
      setImglist([]);
      setNewImageArray([]);
      setSelectedTreatmentQuestions([data.service_questions])
      console.log("selected treatment questions", selectedTreatmentQuestions)
      let list = []
      data.service_questions.map(question => {
        list.push(question.statement)
      })

      setTreatmentQuestionList([...list])

    }
  }, [data]);

  const get_list = async () => {
    const result = await GetSubCategory();

    if (result.code === 200) {
      setListofCategory(result.data.Category);
    }
  };
  useEffect(() => {
    get_list();
  }, []);

  if (data) {
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openEditDialog}
          fullWidth
          maxWidth={"md"}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            sx={{ fontWeight: 500, padding:"16px 35px" }}
            variant="h5"
          >
            Edit Treatment
          </BootstrapDialogTitle>
          <form onSubmit={handleEditData}>
            <DialogContent sx={{padding:"20px 35px"}}>
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    sx={{ my: 2 }}
                    id="title"
                    width="2"
                    required
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    fullWidth
                  ></TextField>

                  <TextField
                    sx={{ mb: 2 }}
                    required
                    id="Category"
                    select
                    label="Category"
                    SelectProps={{ value: category }}
                    onChange={(e) => setCategory(e.target.value)}
                    fullWidth
                  >
                    {listofCategory &&
                      listofCategory.map((list) => (
                        <MenuItem key={list._id} value={list._id}>
                          {list.name}
                        </MenuItem>
                      ))}
                  </TextField>

                  <TextField
                    sx={{ mb: 2 }}
                    select
                    id="status"
                    label="Status"
                    onChange={(e) => setStatus(e.target.value)}
                    SelectProps={{ value: status }}
                    fullWidth
                  >
                    {activeStatus.map((active) => (
                      <MenuItem key={active.value} value={active.value}>
                        {active.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Grid item xs direction="row">
                    <TextField
                      required
                      id="price"
                      label="Price"
                      type={"number"}
                      sx={{ pr: 1, width: "50%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputProps: { min: 0 },
                      }}
                      value={newPrice}
                      onChange={(e) => setPrice(e.target.value)}
                    ></TextField>
                    <TextField
                      sx={{ pl: 1, width: "50%" }}
                      id="Duration"
                      label="Duration (minutes)"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      type={"number"}
                      onChange={(e) => setTime(e.target.value)}
                      required
                      value={time}
                    ></TextField>
                  </Grid>

                  <FormControl fullWidth sx={{ marginTop: "15px" }}>
                    <InputLabel id="demo-multiple-chip-label">Treatment Questions</InputLabel>
                    <Select
                      labelId="Treatment-Questions"
                      id="demo-multiple-chip"
                      multiple
                      fullWidth
                      label="Treatment Questions"
                      value={treatmentQuestionList}
                      onChange={handleChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Treatment Questions" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value, index) => (
                            <Chip key={index} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {
                        treatmentQuestions && treatmentQuestions.map((questions, index) => (
                          <MenuItem
                            key={index}
                            value={questions.statement}
                          >
                            {questions.statement}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="Description"
                    label="Description"
                    multiline
                    fullWidth
                    required
                    rows={11}
                    sx={{ m: 2 }}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{marginTop:"15px"}}>
                <Grid item xs>
                  <Button
                    variant="outlined"
                    sx={{
                      marginBottom:"15px",
                      borderColor: "#C4CDD5",
                      color: "#637381",
                    }}
                    component="label"
                  >
                    Add Image <PhotoCamera sx={{ ml: 1 }} />
                    <input
                      hidden
                      multiple
                      accept="image/*"
                      type="file"
                      onChange={handleNewImage}
                    />
                  </Button>
                  <div className="images">
                    {image &&
                      image.map((img, index) => {
                        return (
                          <div key={index} className="image">
                            <img src={s3baseUrl + img} height="150" alt={img} />
                            <button
                              type="click"
                              onClick={(e) => deleteHandler(e, img, index)}
                            >
                              <CloseIcon sx={{ color: "#212B36" }} />
                            </button>
                          </div>
                        );
                      })}
                    {newImagePreview &&
                      newImagePreview.map((img, index) => {
                        return (
                          <div key={index} className="image">
                            <img src={img} height="150" alt={img} />
                            <button
                              type="click"
                              onClick={(e) => newImageDeleteHandler(e, img, index)}
                            >
                              <CloseIcon sx={{ color: "#212B36" }} />
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </Grid>
              </Grid>


            </DialogContent>

            <DialogActions>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                sx={{ borderRadius: 0.5, m: 1, py: 0.7, px: 1.5 }}
                type="submit"
              >
                Update Treatment
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
