import { invokeApi } from 'src/utils';

export const add_customer_by_admin = async (data) => {
  const requestObj = {
    path: `/api/customer/add_customer_by_admin`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_customer_listing = async (page, limit, data) => {
  const requestObj = {
    path: `/api/customer/get_customers?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_customer = async (id, data) => {
  const requestObj = {
    path: `/api/customer/edit_customer_by_admin/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_customer_password = async (id, data) => {
  const requestObj = {
    path: `/api/customer/customer_change_password/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_customer = async (id) => {
  const requestObj = {
    path: `/api/customer/delete_customer_by_admin/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj);
};