import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  AddUser,
  Dashboard,
  ForgotPassword,
  Login,
  Page404,
  Profile,
  Register,
  Settings,
  Users,
  Services,
  Schedule,
  GrandCategory,
  SubCategory,
  MedicalQuestions,
  TreatmentQuestions,
  Bookings,
  AddCategory,
} from "./pages";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "grandcategory", element: <GrandCategory /> },
        { path: "subcategory", element: <SubCategory /> },
        { path: "services", element: <Services /> },
        { path: "schedule", element: <Schedule /> },
        { path: "medicalquestions", element: <MedicalQuestions /> },
        { path: "treatmentquestions", element: <TreatmentQuestions /> },
        { path: "bookings", element: <Bookings /> },
        { path: "users/add-user", element: <AddUser /> },
        { path: "users/add-category", element: <AddCategory /> },
        { path: "profile", element: <Profile /> },
        { path: "settings", element: <Settings /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        // { path: "register", element: <Register /> },
        // { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
