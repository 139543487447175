import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

import { useEffect, useState } from "react";
import {useSnackbar} from "notistack";
import { delete_customer } from "src/DAL/Users";

export default function DeleteDialog({
  setOpenDialog,
  openDialog,
  editData,
  setIsPending,
  setDrawerCondition,
  drawerCondition,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState("");
  
  const handleDelete = async () => {
    setOpenDialog(false);
   setIsPending(true);
    try {
      const result = await delete_customer(id);
      if (result.code === 200) {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "success" })
        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }
      } else {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "error" })
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (editData) setId(editData.user_id._id);
  }, [editData]);

  if (editData) {
    return (
      <div>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete Customer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlinedInherit"
              sx={{
                borderColor: "#C4CDD5",
                color: "#637381",
                borderRadius: 0.5,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                borderColor: "#C4CDD5",
                color: "#fff",
                borderRadius: 0.5,
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
