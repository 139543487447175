import { Box, Button, Container, IconButton, TextField, Typography } from '@mui/material';
import { Page } from 'src/components';
import InputAdornment from '@mui/material/InputAdornment';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import { Icon } from "@iconify/react";
import { admin_change_password, GetAdminProfile } from 'src/DAL/Profile';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const Profile = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [isPending, setIsPending] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [id, setId] = React.useState("");
  const [profile, setProfile] = React.useState("");
  const navigate = useNavigate();

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowPassword1 = () => setShowPassword1((show) => !show);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();
    if (password === confirmPassword && password !== "") {
      if (password.length > 5) {
        formData.append("password", password);
        formData.append("confirm_password", confirmPassword);
      }
      else {
        enqueueSnackbar("Password must be 6 characters long!", { variant: "error" })
        setIsPending(false);
        return false;
      }
    }

    try {
      const result = await admin_change_password(formData);
      if (result.code === 200) {
        console.log(result);
        setIsPending(false);
        enqueueSnackbar("Password changed successfully!", { variant: "success" })
        setPassword("");
        setConfirmPassword("");
        navigate("/dashboard");
      } else {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "error" })
        setIsPending(false);
      }
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const get_list = async () => {
    const result = await GetAdminProfile();
    setIsPending(true);
    if (result.code === 200) {
      setProfile(result.Saloon);
      setId(result.Saloon.user_id._id);
      setIsPending(false);
    }
  };

  React.useEffect(() => {
    get_list();
  }, []);

  return (
    <Page title='Profile'>
      <Container maxWidth='xl'>
        <Card sx={{ minWidth: 275, marginTop: "12px" }}>
          <CardContent>
            <Typography variant='h4' sx={{ fontWeight: 500, mb: 4 }} gutterBottom>
              Change Password
            </Typography>
            <form onSubmit={handleSubmit} align="center" >
              <Box
                style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                sx={{
                  "& .MuiTextField-root": { ml: 4, mr: 4, mb: 3, width: "80%" },
                }}
                noValidate
                autoComplete="off"
              >
                {/* <FormControl sx={{ width: '50%' }} variant="outlined">

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Old Password"
                    inputProps={{ minlength: 6 }}
                    required
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? "ph:eye-fill" : "ri:eye-off-fill"} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl> */}

                <FormControl sx={{ width: '50%' }} variant="outlined">

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? "ph:eye-fill" : "ri:eye-off-fill"} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: '50%' }} variant="outlined">

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword1 ? "text" : "password"}
                    label="Confirm Password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword1} edge="end">
                            <Icon icon={showPassword1 ? "ph:eye-fill" : "ri:eye-off-fill"} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                {password === confirmPassword && password !== "" &&
                  <Box className="pb-3">
                    <span id="message" style={{ color: "#008000" }}>Passwords matched!</span>
                  </Box>}
                {password !== confirmPassword && password !== "" && confirmPassword !== "" &&
                  <Box className="pb-3">
                    <span id="message" style={{ color: "#df0101" }}>Passwords do not match!</span>
                  </Box>

                }
                <Button type="submit" variant="contained">
                  Update Password
                </Button>
              </Box>
            </form>
          </CardContent>
          <CardActions>
            {/* <Button size="small">Learn More</Button> */}
          </CardActions>
        </Card>


      </Container>
    </Page>
  );

};

export default Profile;
