import { invokeApi } from 'src/utils';

export const GetAdminProfile = async () => {
  const requestObj = {
    path: `/api/saloon/detail_saloon`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const admin_change_password = async (data) => {
  const requestObj = {
    path: `/api/saloon/saloon_change_password`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
