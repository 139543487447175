// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Grid, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "src/utils";
// components
import Iconify from "../../../components/Iconify";
import { textAlign } from "@mui/system";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

SummaryCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard({
  title,
  count,
  icon,
  bcolor,
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        py: 2.5,
        boxShadow: 5,
        textAlign: "left",
        color: (theme) => theme.palette.grey[800],
        bgcolor: "#fff",
        height: 120,
        borderRadius: "3px",
        ...sx,
      }}
      {...other}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={6} sx={{ mt: 1, pl: 2 }}>
          <Typography variant="h6" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {fShortenNumber(count)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1, pl: 8 }}>
          <IconWrapperStyle
            sx={{
              color: "white",
              bgcolor: bcolor,
              // backgroundImage: (theme) =>
              //   `linear-gradient(135deg, ${alpha(
              //     theme.palette[color].dark,
              //     0
              //   )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
            }}
          >
            <Iconify icon={icon} width={24} height={24} />
          </IconWrapperStyle>
        </Grid>
      </Grid>
    </Card>
  );
}
