import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { s3baseUrl } from "src/config/config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Img = styled("img")({
  //   margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Details({ data }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        sx={{ p: 0, pt: 0.25 }}
        color={"info"}
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
      >
        View
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: 500, }}
          variant="h5"
        >
          Treatment Question
        </BootstrapDialogTitle>


        <DialogContent >
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="question_type"
                className="disabled-field"
                label="Question Type"
                value={data.statement_type}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="question_status"
                className="disabled-field"
                label="Status"
                value={data.status === true ? "Active" : "In-Active"}
                disabled
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="question"
                className="disabled-field"
                label="Question"
                value={data.statement}
                fullWidth
                multiline
                maxRows={4}
                disabled
              />
            </Grid>

            {data.statement_type !== "QUESTION" && JSON.parse(data.option).map((answer, index) => {
              return <Grid key={index} item xs={12}> <TextField id="answer"
                className="disabled-field"
                label={`Option ${index + 1}`}
                multiline
                fullWidth
                maxRows={8}
                value={answer}
                disabled
              />
              </Grid>
            })
            }

          </Grid>




        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            sx={{ m: 1, borderRadius: 0.5 }}
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
