import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { edit_customer } from "src/DAL/Users";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function StatusMenu({
  // status,
  // setStatus,
  get_list,
  data,
  setIsPending,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState();
  const [status, setStatus] = useState(true);

  const SetActive = async () => {
    setIsOpen(false);
    setIsPending(true);
    setStatus(true);
    // const formData = new FormData();
    // formData.append("status", status);

    let result = await edit_customer(id, {
      status: true,
    });
    if (result.code == 200) {
      setIsPending(false);
      get_list();
    } else {
    }
  };

  const SetInActive = async () => {
    setIsOpen(false);
    setIsPending(true);

    setStatus(false);
    // const formData = new FormData();
    // formData.append("status", status);

    let result = await edit_customer(id, {
      status: false,
    });
    if (result.code == 200) {
      setIsPending(false);
      get_list();
    } else {
    }
  };

  useEffect(() => {
    if (data) {
      setId(data._id);
      setStatus(data.status);
    }
  }, [data]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ py: 0, px: "8px", fontSize: "10.2857px", fontWeight: 700, height: "22px", minWidth: "22px", lineHeight: 0, borderRadius: "6px" }}
        color={(data.status === false && "error") || "success"}
        // onClick={() => setIsOpen(true)}
        // ref={ref}
        ref={ref}
        onClick={() => setIsOpen(true)}
      >
        {data.status === true ? "Active" : "In-Active"}
      </Button>

      {/* <IconButton >
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton> */}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 150, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {data.status === true ? (
          <MenuItem sx={{ color: "text.secondary" }} onClick={SetInActive}>
            <ListItemText
              primary="In-Active"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          <MenuItem sx={{ color: "text.secondary" }} onClick={SetActive}>
            <ListItemText
              primary="Active"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
