import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import { Iconify } from "src/components";
import { PhotoCamera, SettingsOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { GetSubCategory } from "src/DAL/SubCategory";
import { AddNewService, UploadServiceImage } from "src/DAL/Services";
import { useSnackbar } from "notistack";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { GetMedicalQuestions } from "src/DAL/MedicalQuestions";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { GetTreatmentQuestions } from "src/DAL/TreatmentQuestions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const Img = styled("img")({
//   //   margin: "auto",
//   display: "block",
//   maxWidth: "100%",
//   maxHeight: "100%",
// });

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const activeStatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "In-Active",
  },
];

export default function AddService({ drawerCondition, setDrawerCondition, setIsPending }) {
  const [open, setOpen] = useState(false);
  const [listofCategory, setListofCategory] = useState();
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");
  // const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState([]);
  const [selectedFilesArray, setSelectedFilesArray] = useState([]);
  const [imglist, setImglist] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [treatmentQuestions, setTreatmentQuestions] = useState();
  const [count, setCount] = useState(0);
  const [checkBoxQuestions, setCheckBoxQuestions] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteHandler = (image, index) => {
    imagePreview.splice(index, 1);
    setImagePreview([...imagePreview]);
    selectedFilesArray.splice(index, 1);
    setSelectedFilesArray([...selectedFilesArray]);
  };

  const handleClose = () => {
    setOpen(false);
    setCategory("");
    setTitle("");
    setPrice("");
    setTime("");
    setDescription("");
    setImagePreview([]);
    setImglist([]);
    setSelectedFilesArray([]);
  };

  const handleImage = async (e) => {
    let imgPreview = e.target.files;
    console.log(imgPreview);
    let imagesList = Array.from(imgPreview);
    imgPreview = null;
    console.log(imgPreview);
    setSelectedFilesArray([...imagesList]);

    const imagesArray = imagesList.map((file) => {
      return URL.createObjectURL(file);
    });

    setImagePreview((p) => p.concat(imagesArray));
  };

  const uploadData = async () => {
    let SelectedQuestionArray = []
    treatmentQuestionList.map(questionTitle => {
      treatmentQuestions.map(questions => {
        if (questionTitle == questions.statement) {
          SelectedQuestionArray.push(questions)
        }
      })

    })
    let result = await AddNewService({
      title: title,
      description: description,
      category: category,
      price: price,
      time: time,
      status: status,
      top_trending: false,
      image: imglist,
      service_questions: SelectedQuestionArray,
    });
    if (result.code == 200) {
      {
        drawerCondition ? setDrawerCondition(false) : setDrawerCondition(true);
      }
      handleClose();
      enqueueSnackbar("Treatment added successfully", { variant: "success" })

    }
    else {
      enqueueSnackbar(result.message, { variant: "error" })
    }
  };

  // Medical Questions API
  const getTreatmentQuestionData = async () => {
    try {
      const result = await GetTreatmentQuestions();
      if (result.code == 200) {

        setTreatmentQuestions(result.data.service_questions);
        setCount(result.data.count);
        console.log(treatmentQuestions, 'treatment_questions');

      } else {
        console.log(result, 'error');
      }
    } catch (error) {
      console.log(error, "catch");
    }
  }
  // useEffect to get medical questions data
  useEffect(() => {
    getTreatmentQuestionData();
  }, []);

  useEffect(() => {
    if (imglist.length == selectedFilesArray.length && imglist.length != 0) {
      uploadData();
    }
  }, [imglist]);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (selectedFilesArray.length == 0) {
      enqueueSnackbar("Image not found!", { variant: "error" });
      return
    }
    setIsPending(true);
    setOpen(false);
    setImglist([]);
    console.log(selectedFilesArray, "selectedFilesArrayselectedFilesArrayselectedFilesArrayselectedFilesArray")

    {
      selectedFilesArray &&
        selectedFilesArray.map(async (img) => {
          let formdata = new FormData();
          formdata.append("image", img);
          let result = await UploadServiceImage(formdata);
          if (result.code == 200) {
            imglist.push(result.path);
            setImglist([...imglist]);
            // enqueueSnackbar(result.messageimglist, { variant: "success" })
          }
          else {
            // enqueueSnackbar(result.message, { variant: "error" })
          }
        });
    }
    // if (selectedFilesArray.length == 0) {
    //   uploadData();
    // }
  };

  const get_list = async () => {
    const result = await GetSubCategory();

    if (result.code === 200) {
      setListofCategory(result.data.Category);
    }
  };
  useEffect(() => {
    get_list();
  }, []);

  const theme = useTheme();
  const [treatmentQuestionList, setTreatmentQuestionList] = React.useState([]);

  const handleChange = (event) => {
    const { target: { value } } = event;

    setTreatmentQuestionList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,

    );

  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
      >
        Add Treatment
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: 500 }}
          variant="h5"
        >
          Add Treatment
        </BootstrapDialogTitle>
        <form margin="0" onSubmit={handleAdd}>
          <DialogContent>
            <Grid container columnSpacing={2} sx={{ p: 1 }}>
              <Grid item xs={6} sx={{ mb: 1 }}>
                <TextField
                  sx={{ mb: 2 }}
                  id="name"
                  required
                  fullWidth
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></TextField>

                <TextField
                  select
                  sx={{ mb: 2 }}
                  label="Category"
                  required
                  fullWidth
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {listofCategory &&
                    listofCategory.map((list) => (
                      <MenuItem key={list._id} value={list._id}>
                        {list.name}
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  select
                  sx={{ mb: 2 }}
                  label="Status"
                  value={status}
                  fullWidth
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {activeStatus.map((active) => (
                    <MenuItem key={active.value} value={active.value}>
                      {active.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Grid item xs container direction="row">
                  <TextField
                    id="price"
                    required
                    label="Price"

                    type={"number"}
                    sx={{ pr: 1, width: "50%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0 }

                    }}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></TextField>

                  <TextField
                    sx={{ width: "50%" }}
                    id="Duration"
                    type={"number"}
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                    label="Duration (minutes)"
                    value={time}
                    required
                    onChange={(e) => setTime(e.target.value)}
                  ></TextField>
                </Grid>

                <FormControlLabel sx={{ p: 1 }}
                  label="Add Treament Questions?"
                  control={
                    <Checkbox
                      onChange={() => {
                        setTreatmentQuestionList([])
                        setCheckBoxQuestions(!checkBoxQuestions)
                      }}
                      checked={checkBoxQuestions}
                    />
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-chip-label">Treatment Questions</InputLabel>
                  <Select
                    disabled={!checkBoxQuestions}
                    labelId="Medical-Questions"
                    id="demo-multiple-chip"
                    multiple
                    fullWidth
                    label="Medical Questions"
                    value={treatmentQuestionList}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Treatment Questions" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      treatmentQuestions && treatmentQuestions.map((questions, index) => (
                        <MenuItem
                          key={index}
                          value={questions.statement}
                        >
                          {questions.statement}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sx={{ mb: 2 }} >
                <TextField
                  id="Description"
                  label="Description"
                  multiline
                  fullWidth
                  required
                  rows={11}

                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Button
                  variant="outlined"
                  sx={{
                    mt: 2,
                    mb: 2,
                    borderColor: "#C4CDD5",
                    color: "#637381",
                  }}
                  component="label"
                >
                  Select Image <PhotoCamera sx={{ ml: 1 }} />
                  <input
                    hidden
                    multiple
                    accept="image/*"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
                <div className="images">
                  {imagePreview &&
                    imagePreview.map((image, index) => {
                      return (
                        <div key={index} className="image">
                          <img src={image} height="150" width="250" alt="upload" style={{ objectFit: "cover", objectPosition: "center" }} />
                          <button onClick={() => deleteHandler(image, index)}>
                            <CloseIcon sx={{ color: "#212B36" }} />
                          </button>
                        </div>
                      );
                    })}
                </div>
              </Grid>
            </Grid>


            <Grid container direction="row" sx={{ p: 1 }}>
              <Grid item xs container direction="column">

              </Grid>
              <Grid item xs sx={{ ml: 2, mb: 2 }}>

              </Grid>
            </Grid>


          </DialogContent>

          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              sx={{ borderRadius: 0.5, m: 1, py: 0.5 }}
              type="submit"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Treatment
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
