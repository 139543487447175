import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { edit_customer } from "src/DAL/Users";
import { useEffect } from "react";
import { edit_booking_status } from "src/DAL/BookingListing";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

export default function StatusMenu({
  // status,
  // setStatus,
  get_list,
  data,
  setIsPending,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState();
  const [status, setStatus] = useState(true);
  const [bookingStatus, setBookingStatus] = useState("");
  const [salonId, setSalonId] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  console.log(data, "rowwwwwww")
  const SetActive = async () => {
    setIsOpen(false);
    setIsPending(true);
    setStatus(true);
    // const formData = new FormData();
    // formData.append("status", status);

    // let result = await edit_customer(id, {
    //   status: true,
    // });
    // if (result.code == 200) {
    //   setIsPending(false);
    //   get_list();
    // } else {
    // }
  };

  const changeBookingStatus = async (bookingStatus) => {
    setIsOpen(false);
    setIsPending(true);

    // setStatus(false);
    const formData = new FormData();
    formData.append("booking_status", bookingStatus);
    formData.append("salon_id", salonId);
    formData.append("booking_id", id);

    let result = await edit_booking_status(formData);
    if (result.code == 200) {
      enqueueSnackbar("Status changed!", { variant: "success" })
      setIsPending(false);
      get_list();
    } else {
      enqueueSnackbar("Status not changed!", { variant: "error" })
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (data) {
      setId(data._id);
      setStatus(data.booking_status);
      setSalonId(data.saloon._id);
    }
  }, [data]);

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        sx={{fontSize: "10px", p:"2px 6px"}}
        color={(data.booking_status === "Pending") ? "warning" : data.booking_status === "Cancel" ? "error" : "success"}
        // onClick={() => setIsOpen(true)}
        // ref={ref}
        ref={ref}
        onClick={() => setIsOpen(true)}
      >
        {data.booking_status}
      </Button>

      {/* <IconButton >
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton> */}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 150, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {
          data.booking_status === "Pending" && <Box>
            <MenuItem sx={{ color: "text.secondary" }} onClick={() => changeBookingStatus("Complete")}>
              <ListItemText
                primary="Complete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <MenuItem sx={{ color: "text.secondary" }} onClick={() => changeBookingStatus("Cancel")}>
              <ListItemText
                primary="Cancel"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Box>
        }
        {
          data.booking_status === "Complete" && <Box>
            <MenuItem sx={{ color: "text.secondary" }} onClick={() => changeBookingStatus("Pending")}>
              <ListItemText
                primary="Pending"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <MenuItem sx={{ color: "text.secondary" }} onClick={() => changeBookingStatus("Cancel")}>
              <ListItemText
                primary="Cancel"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Box>
        }
        {
          data.booking_status === "Cancel" && <Box>
            <MenuItem sx={{ color: "text.secondary" }} onClick={() => changeBookingStatus("Complete")}>
              <ListItemText
                primary="Complete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            <MenuItem sx={{ color: "text.secondary" }} onClick={() => changeBookingStatus("Pending")}>
              <ListItemText
                primary="Pending"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </Box>
        }
      </Menu>
    </div>
  );
}
