import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// import { Form, FormikProvider } from "formik";
// import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Button,
  Drawer,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Container,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Divider

} from "@mui/material";
import { Iconify } from "src/components";
import { Box } from "@mui/system";
import { Add, Delete, PhotoCamera } from "@mui/icons-material";
import { AddCategory } from "src/DAL/GrandCategory";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import { GetMedicalQuestions, PostMedicalQuestions } from "src/DAL/MedicalQuestions";
import { PostTreatmentQuestions } from "src/DAL/TreatmentQuestions";

// ----------------------------------------------------------------------

// AddDrawer.propTypes = {
//   isOpenDrawer: PropTypes.bool,
//   onResetDrawer: PropTypes.func,
//   onOpenDrawer: PropTypes.func,
// };

const activeStatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "In-Active",
  },
];

export default function AddDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  drawerCondition,
  setIsPending,
  setDrawerCondition,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [questionStatement, setQuestionStatement] = useState("");
  const [questionType, setQuestionType] = useState();
  const [description, setDescription] = useState([]);
  const [status, setStatus] = useState(true);
  const [mcqOptions, setMcqOptions] = useState(['', '']);


  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);

    const formData = new FormData();

    try {
      console.log("formdata", ...formData)
      const result = await PostTreatmentQuestions({ statement_type: questionType, statement: questionStatement, option: JSON.stringify(mcqOptions), status: status });
      if (result.code == 200) {
        console.log(result, 'res');
        setOpenAddDrawer(false);
        setIsPending(false);
        enqueueSnackbar("Treatment question added successfully!", { variant: "success" })
        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }
        setMcqOptions(["", ""])
        setQuestionType()
        setQuestionStatement("")
      } else {
        console.log(result, 'error');
        enqueueSnackbar(result.message, { variant: "error" })
        setIsPending(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleImage = (e) => {
  //   setChangeImage(e.target.files[0]);
  //   const selectedFile = e.target.files[0];

  //   const imagesArray = URL.createObjectURL(selectedFile);

  //   setSelectedImage(imagesArray);
  // };



  console.log(questionStatement, "klsklfls")
  const handleChange = (event) => {
    console.log(event.target.value, "value")
    setQuestionType(event.target.value);

  };

  const handleDelete = (index) => {
    if (mcqOptions.length > 2) {
      console.log("index", index)
      let list = [...mcqOptions]
      list.splice(index, 1)
      setMcqOptions([...list])
    }
  }
  useEffect(() => {
    if (questionType === 'YES_OR_NO') { setMcqOptions(["YES", "NO"]) }
    else {
      setMcqOptions(["", ""])
    }

  }, [questionType])



  return (
    <>

      <Drawer
        className="forms-drawer event-title"
        anchor="right"
        open={openAddDrawer}
        onClose={() => {
          setOpenAddDrawer(false);
          setQuestionType("");
          setQuestionStatement("");
          setDescription("");
        }}
        PaperProps={{
          sx: { width: 350, border: "none" },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 500, textAlign: "center", mt: 6, mb: 3 }}
        >

          Add Question
        </Typography>
        <form onSubmit={handleSubmit}>

          {/* select questions */}
          <Container>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-helper-label">Question Type</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={questionType}
                label="Question Type"
                onChange={handleChange}
              >
                <MenuItem value="QUESTION">STATEMENT</MenuItem>
                <MenuItem value="MCQ">MCQ</MenuItem>
                <MenuItem value="YES_OR_NO">YES OR NO</MenuItem>
              </Select>
            </FormControl>

            {
              questionType === "QUESTION" &&
              <>
                <FormControl fullWidth>
                  <TextField width={100} sx={{ mb: 5 }}
                    id="outlined-basic"
                    variant="outlined"
                    label="Question Statement"
                    required
                    value={questionStatement}
                    onChange={(e) => setQuestionStatement(e.target.value)
                    }
                  />
                </FormControl>
              </>
            }
            {
              questionType === "MCQ" &&
              <>

                <FormControl fullWidth>
                  <TextField width={100} sx={{ mb: 2 }}
                    id="outlined-basic"
                    variant="outlined"
                    label="Question Statement"
                    required
                    value={questionStatement}
                    onChange={(e) => setQuestionStatement(e.target.value)}
                  />
                </FormControl>
                {
                  mcqOptions.map((field, index) => (
                    <FormControl key={index} fullWidth sx={{ mb: 2 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">Option {index + 1}</InputLabel>
                      <OutlinedInput
                        className="option-field"
                        value={field}
                        required
                        onChange={(e) => {
                          mcqOptions[index] = e.target.value
                          setMcqOptions([...mcqOptions])
                        }}
                        id="outlined-adornment-amount"
                        endAdornment={mcqOptions.length > 2 && <InputAdornment position="end" onClick={() => { handleDelete(index) }}><ClearIcon /></InputAdornment>}
                        label="Delete"
                      />
                    </FormControl>

                  ))

                }
                {/* add field button */}
                <FormControl fullWidth sx={{ mb: 3, display: "flex", alignItems: "end" }}>
                  <Button id="create-btn"
                    sx={{ float: "right", width: 60 }}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => setMcqOptions([...mcqOptions, ''])}
                  >
                  </Button>
                </FormControl>


              </>


            }

            {
              questionType === "YES_OR_NO" &&
              <>
                <FormControl fullWidth>
                  <TextField width={100} sx={{ mb: 2 }}
                    id="outlined-basic"
                    variant="outlined"
                    label="Question Statement"
                    required
                    value={questionStatement}
                    onChange={(e) => setQuestionStatement(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField width={100} sx={{ mb: 2 }}
                    id="outlined-basic"
                    variant="outlined"

                    required
                    value={mcqOptions[0]}
                    disabled
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField width={100} sx={{ mb: 5 }}
                    id="outlined-basic"
                    variant="outlined"

                    required
                    value={mcqOptions[1]}
                    disabled
                  />
                </FormControl>
              </>
            }
            <FormControl fullWidth sx={{mb:2}}>
              <TextField
                select
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {activeStatus.map((active) => (
                  <MenuItem key={active.value} value={active.value}>
                    {active.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>


            <FormControl fullWidth>
              <Button
                sx={{ mx: "auto" }}
                type="submit"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Question
              </Button>
            </FormControl>

          </Container>

        </form>
      </Drawer>
    </>
  );
}
