import { useRef, useState } from "react";
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//hooks
import { useAppContext } from "src/hooks";
import { getIcon, Icon } from "@iconify/react";
import { useEffect } from "react";
import { Iconify } from "src/components";
import { GetAdminProfile } from "src/DAL/Profile";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Change Password",
    path: "/profile",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [profile, setProfile] = useState();
  // const { _get_user_profile } = useAppContext();
  // const profile = _get_user_profile();
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  const handleNavigate = (path) => {
    navigate(path);
  };
  
  //-------------------------------------------------------------------
  const get_Profile = async () =>{
    const result = await GetAdminProfile();
    if(result.code === 200){
      setProfile(result.Saloon);
    }
  }
  
    useEffect(()=>{
      get_Profile();
    },[])
  //-------------------------------------------------------------------
  
  if(profile){
  return (
    
    <>
      <IconButton
      ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              // bgcolor: (theme) => alpha(theme.palette.grey[400], 0.8),
            },
          }),
        }}
      >
        {profile.profile_image ? (
          <>
            <Avatar src={s3baseUrl + profile.profile_image} alt="photoURL" />
          </>
        ) : (
          <>
            <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
              {profile.name.charAt(0).toUpperCase()}
            </Avatar>
          </>
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {profile.user_id.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "solid" }} color="#212B36" />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.path}
              component={RouterLink}
              // onClick={() => handleNavigate(option.path)}
              sx={{
                color: match(option.path)
                  ? (theme) => theme.palette.primary.main
                  : "black",
                bgcolor: match(option.path)
                  ? (theme) =>
                      alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                      )
                  : "",
                  "&:hover":{
                    color: (theme) => theme.palette.grey[800],
                  },
              }}
              onClick={handleClose}
            >
          <Iconify icon="arcticons:nc-passwords" color="#000"  marginRight={1} width={18} height={18} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "solid" }} color="#212B36" />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }} 
        
        >
          <Iconify icon="gg:log-off" color="#637381" marginRight={1} width={16} height={16} />
          Logout
        </MenuItem>
      </MenuPopover>
    </>
    );
  }
}
