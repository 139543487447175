import { filter } from "lodash";
import { s3baseUrl } from "src/config/config";
import { sentenceCase } from "change-case";
import { useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  List,
  Divider,
  TextField,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import ListHead from "./components/ListHead";
import ListToolbar from "./components/ListToolbar";
import MoreMenu from "./components/MoreMenu";
// DAL
import { GetCategoryListing } from "src/DAL/GrandCategory";
//
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CleaningServices } from "@mui/icons-material";
import users from "src/_mock/user";
import { LoadingButton } from "@mui/lab";
import AddDrawer from "./components/AddDrawer";
import EditDrawer from "./components/EditDrawer";
import DeleteDialog from "./components/DeleteDialog";
import Details from "./components/Details";

import { GetBookingListing } from "src/DAL/BookingListing";
import StatusMenu from "./components/StatusMenu";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "userName", label: "Customer Name", alignRight: false },
  { id: "treatments", label: "Treatments", alignRight: false },
  { id: "treatmentsCost", label: "Treatment Cost", alignRight: false },
  { id: "appointmentDate", label: "Appointment Date", alignRight: false },
  { id: "appointmentTime", label: "Appointment Time", alignRight: false },
  { id: "appointmentStatus", label: "Status", alignRight: false },
  { id: "appointmentDetails", label: "Details", alignRight: false },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   console.log(users)
//   if (users){
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
//   }}

export default function Bookings() {

  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [isPending, setIsPending] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [drawerState, setDrawerState] = useState(false);
  const [editData, setEditData] = useState();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [drawerCondition, setDrawerCondition] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const [bookingListingApi, setBookingListingApi] = useState();
  const [treatments, setTreatments] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = bookingListingApi.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleAdd = () => {
    handleOpenDrawer();
    // handleOpenEditDrawer();
  };
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  //====================================================
  const [editDrawerState, setEditDrawerState] = useState(false);

  const handleEdit = () => {
    handleEditDrawer();
    setEditData(data);
    // handleOpenEditDrawer();
  };
  const handleEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  //======================================================
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingListingApi.length) : 0;

  const isUserNotFound = bookingListingApi && bookingListingApi.length === 0;

  const getBookingListingFunction = async () => {
    try {
      const result = await GetBookingListing( filterName, page, rowsPerPage );
      
      if (result.code == 200) {
console.log(result,"resulttt")
        setBookingListingApi(result.booking_list.booking);
        setCount(result.booking_list.count);
        setIsPending(false)
        console.log(bookingListingApi, 'booking listing res');

      } else {
        console.log(result, 'errr');
      }
    } catch (error) {
      console.log(error, "catch");
    }
  }

  useEffect(() => {
    if (filterName !== "") setPage(0);
  }, [filterName]);

  useEffect(() => {
    getBookingListingFunction();
  }, [filterName, page, rowsPerPage, drawerCondition]);


  return (
    <Page title="Bookings">
      <Container maxWidth="xl">
        {isPending && (
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress />
          </Backdrop>
        )}
        <AddDrawer
          openAddDrawer={openAddDrawer}
          setIsPending={setIsPending}
          setOpenAddDrawer={setOpenAddDrawer}
          drawerCondition={drawerCondition}
          setDrawerCondition={setDrawerCondition}
        />
        {editData && openEditDrawer && <EditDrawer
          openEditDrawer={openEditDrawer}
          setOpenEditDrawer={setOpenEditDrawer}
          editData={editData}
          setIsPending={setIsPending}
          setEditData={setEditData}
          setDrawerCondition={setDrawerCondition}
          drawerCondition={drawerCondition}
        />}
        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1.5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.5}
            mt={2.5}
            mx={3.5}
          >

            <div>
              <Typography variant="h4" sx={{ fontWeight: 500, mb: 2.5 }}>
                Bookings
              </Typography>
              {/* <Button

                onClick={() => setOpenAddDrawer(true)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Question
              </Button> */}
            </div>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={bookingListingApi && bookingListingApi.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {
                  // bookingListingApi &&
                  // bookingListingApi
                  //   .map((row, index) => {
                  //     console.log("listing",row)
                  //   })
                }
                <TableBody>
                  {
                    bookingListingApi &&
                    bookingListingApi.map((row, index) => {
                        const { _id, userName = row.user.name, price, date, startTime = row.slots.start_time, endTime = row.slots.end_time, booking_status } = row;
                        const isItemSelected = selected.indexOf(userName) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell>
                              <Typography
                                sx={{
                                  width: "50px"
                                }}>
                                {rowsPerPage * page + index + 1}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                  overflow: "hidden",
                                  width: "150px"
                                }}
                              >
                                {userName}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap
                                sx={{ width: "150px" }}>

                                {
                                  row.service.map(val => <li style={{ listStyleType: "number" }}>{val.title}</li>)

                                }
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                  overflow: "hidden",
                                  textAlign: "center",
                                  width: "150px"
                                }}
                              >
                                {price}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                  overflow: "hidden",
                                  textAlign: "center",
                                  width: "150px"
                                }}
                              >
                                {date}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                  overflow: "hidden",
                                  width: "150px"
                                }}
                              >
                                {startTime + ' - ' + endTime}
                              </Typography>
                            </TableCell>

                            {/* <TableCell>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                  overflow: "hidden",
                                  width: "150px"
                                }}
                              >
                                {booking_status}
                              </Typography>
                            </TableCell> */}

                            <TableCell align="left">
                              <Typography
                                sx={{
                                  width: "100px"
                                }}>
                                <StatusMenu
                                  get_list={getBookingListingFunction}
                                  data={row}
                                  setIsPending={setIsPending}
                                />
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                              sx={{
                                width: "100px"
                              }}>
                                <Details data={row} />
                              </Typography>
                            </TableCell>

                            {/* <TableCell align="right">
                              <MoreMenu
                                setOpenEditDrawer={setOpenEditDrawer}
                                data={row}
                                editData={editData}
                                setEditData={setEditData}
                                setOpenDialog={setOpenDialog}
                              />
                              <DeleteDialog
                                setOpenDialog={setOpenDialog}
                                openDialog={openDialog}
                                editData={editData}
                                setIsPending={setIsPending}
                                drawerCondition={drawerCondition}
                                setDrawerCondition={setDrawerCondition}
                              />
                            </TableCell> */}
                          </TableRow>
                        );
                      })}

                </TableBody>

                {(isUserNotFound &&
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
