import { invokeApi } from 'src/utils';

export const PostMedicalQuestions = async (data) => {
  const requestObj = {
    path: `/api/medical_question/add_medical_question`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const GetMedicalQuestions = async (search,page, limit ) => {
  const requestObj = {
    path: `/api/medical_question/list_medical_question?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: {search},
  };
  return invokeApi(requestObj);
};

export const EditMedicalQuestions = async (id,data) => {
  const requestObj = {
    path: `/api/medical_question/edit_medical_question/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const DeleteQuestion = async (id) => {
  const requestObj = {
    path: `/api/medical_question/delete_medical_question/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj);
};
