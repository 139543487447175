import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

export default function ErrorDialog({
  setServerErrorDialog,
  serverErrorDialog,
}) {
  
    const handleClose = () => {
      setServerErrorDialog(false);
    };
    
    return (
      <div>
        <Dialog
          open={serverErrorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete Category?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                borderColor: "#C4CDD5",
                color: "#637381",
                borderRadius: 0.5,
              }}
              >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
