import { s3baseUrl } from "src/config/config";
import { useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  AvatarGroup,
  Tooltip,
  ImageList,
  ImageListItem,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import ListHead from "./components/ListHead";
import ListToolbar from "./components/ListToolbar";
import MoreMenu from "./components/MoreMenu";

//
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import EditService from "./components/EditService";
import DeleteDialog from "./components/DeleteDialog";
import { EditServiceAPI, GetServices } from "src/DAL/Services";
import ServiceDetails from "./components/Details";
import AddService from "./components/AddService";
import TopTrending from "./components/TopTrending";
//
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "checkbox", label: "Top Trending", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  // { id: "category", label: "Category", alignRight: false },
  // { id: "subcategory", label: "Child Category", alignRight: false },
  // { id: "description", label: "Description", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  { id: "Duration", label: "Duration (minutes)", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "details", label: "Details", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Services() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [services, setServices] = useState();
  const [isPending, setIsPending] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editData, setEditData] = useState();

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [drawerCondition, setDrawerCondition] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [displayImage, setDisplayImage] = useState([]);
  const [displaySingleImage, setDisplaySingleImage] = useState("");

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const [open, setOpen] = useState(false);
const handleClickViewImage = (img) => {
  if(img.length <= 1) {
    handleClickOpenSingle(img)
  }
  else {
    handleClickOpen(img)
  }
}
  const handleClickOpen = (img) => {
    setDisplayImage(img);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDisplayImage([]);
  };
  //modal for single
  const [openSingle, setOpenSingle] = useState(false);
  const handleClickOpenSingle = (img) => {
    setDisplaySingleImage(img);
    setOpenSingle(true);
  };
  const handleCloseSingle = () => {
    setOpenSingle(false);
    setDisplaySingleImage("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subCategories.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = services && services.length === 0;

  const get_list = async () => {
    const result = await GetServices(page, rowsPerPage, {
      search: filterName,
    });

    if (result.code === 200) {
      setServices(result.Services.Services);
      setCount(result.Services.count);
      setIsPending(false);
    }
    if (result.code !== 200) {
      setIsPending(false);
    }
  };

  useEffect(() => {
    get_list();
  }, [page, rowsPerPage, filterName, drawerCondition]);

  useEffect(() => {
    if (filterName !== "") setPage(0);
  }, [filterName]);

  return (
    <Page title="Treatments">
      <Container maxWidth="xl">
        {isPending && (
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress />
          </Backdrop>
        )}
        <EditService
          openEditDialog={openEditDialog}
          setOpenEditDialog={setOpenEditDialog}
          data={editData}
          setIsPending={setIsPending}
          setData={setEditData}
          setDrawerCondition={setDrawerCondition}
          drawerCondition={drawerCondition}
        />

        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1.5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.5}
            mt={2.5}
            mx={3.5}
          >
            <div>
              <Typography variant="h4" sx={{ fontWeight: 500, mb: 2.5 }}>
                Treatments
              </Typography>

              <AddService
                drawerCondition={drawerCondition}
                setIsPending={setIsPending}
                setDrawerCondition={setDrawerCondition}
              />
            </div>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={services && services.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {displayImage.length > 0 &&
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

                    </BootstrapDialogTitle>
                    <DialogContent>
                      <ImageList sx={{ width: 490, height: 370 }} cols={3} rowHeight={100}>
                        {displayImage.map((item) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${s3baseUrl + item}`}
                              alt={item.title}
                              loading="lazy"
                              onClick={() => handleClickOpenSingle(item)}
                              className="image-spacing"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>


                    </DialogContent>

                  </BootstrapDialog>}
                  {/* modal for single */}
                  {displaySingleImage !== "" &&
                  <BootstrapDialog
                    onClose={handleCloseSingle}
                    aria-labelledby="customized-dialog-title"
                    open={openSingle}
                    width="600px"
                    height="auto"
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseSingle}>

                    </BootstrapDialogTitle>
                    <DialogContent>
                      <img
                        src={`${s3baseUrl + displaySingleImage}`}
                        alt="image"
                        loading="lazy"
                        width={"100%"}
                        height="100%"
                        className="image-spacing"
                      />

                    </DialogContent>

                  </BootstrapDialog>}
                <TableBody>
                  {services &&
                    services
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const {
                          _id,
                          title,
                          status,
                          category,
                          grand_category,
                          description,
                          top_trending,
                          image,
                          price,
                          order,
                          time,
                        } = row;
                        const isItemSelected = selected.indexOf(title) !== -1;

                        return (
                          <>

                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>
                                {rowsPerPage * page + index + 1}
                              </TableCell>
                              <TableCell>
                                <TopTrending
                                  data={row}
                                  setIsPending={setIsPending}
                                />
                              </TableCell>

                              <TableCell align="left">
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                >
                                  {title}
                                </Typography>
                              </TableCell>

                              <TableCell align="left">
                                <AvatarGroup total={image.length} onClick={() => handleClickViewImage(image)} sx={{ justifyContent: "flex-end" }}>
                                  <Avatar
                                    sx={{ alignSelf: "left" }}
                                    alt={title}
                                    src={s3baseUrl + image[0]}
                                    
                                  />
                                </AvatarGroup>
                              </TableCell>

                              <TableCell>
                                <Typography>${price}</Typography>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ textAlign: "center" }}>{time}</Typography>
                              </TableCell>

                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === false && "error") || "success"
                                  }
                                >
                                  {status === true ? "Active" : "In-Active"}
                                </Label>
                              </TableCell>

                              <TableCell>
                                <ServiceDetails data={row} />
                              </TableCell>

                              <TableCell align="right">
                                <MoreMenu
                                  setOpenEditDialog={setOpenEditDialog}
                                  data={row}
                                  editData={editData}
                                  setEditData={setEditData}
                                  setOpenDialog={setOpenDialog}
                                />
                                <DeleteDialog
                                  setOpenDialog={setOpenDialog}
                                  openDialog={openDialog}
                                  editData={editData}
                                  setIsPending={setIsPending}
                                  drawerCondition={drawerCondition}
                                  setDrawerCondition={setDrawerCondition}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
