import React, { useState, useEffect } from 'react';
import { GetAdminProfile } from 'src/DAL/Profile';

const Context = React.createContext();
const AppContext = ({ children }) => {
  const [profile, setProfile] = useState();
  //------------------------------------------------------
  const _get_user_profile = () => {
    return profile;
  };
  const _set_user_profile = (value) => {
    setProfile(value);
  };

  //------------------------------------------------------

  //------------------------------------------------------
  const bundle = {
    _get_user_profile,
    _set_user_profile,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
