import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
// import { Form, FormikProvider } from "formik";
// import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Button,
  Drawer,
  Typography,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import IconButton from '@mui/material/IconButton';


import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { edit_customer_password, get_customer_listing } from "src/DAL/Users";

import { Icon } from "@iconify/react";


// ----------------------------------------------------------------------

// EditDrawer.propTypes = {
//   isOpenDrawer: PropTypes.bool,
//   onResetDrawer: PropTypes.func,
//   onOpenDrawer: PropTypes.func,
// };

// const activeStatus = [
//   {
//     value: true,
//     label: "Active",
//   },
//   {
//     value: false,
//     label: "In-Active",
//   },
// ];

export default function EditDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editData,
  setEditData,
  setIsPending,
  drawerCondition,
  setDrawerCondition,
}) {

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowPassword1 = () => setShowPassword1((show) => !show);

  const { enqueueSnackbar } = useSnackbar();
  const [listCustomer, setListCustomer] = useState();

  const [id, setId] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatched, setPasswordMatched] = useState(false);

  //   const handleChange = (event) => {
  //     setActive(event.target.value);
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();

    if (password === confirmPassword && password !== "") {
      if (password.length > 5) {
        formData.append("password", password);
        formData.append("confirm_password", confirmPassword);
      }
      else {
        enqueueSnackbar("Password must be 6 characters long!", { variant: "error" })
        setIsPending(false);
        return false;
      }
    }
    try {
      const result = await edit_customer_password(id, formData);
      if (result.code === 200) {
        console.log(result);
        enqueueSnackbar("Password changed successfully!", { variant: "success" })
        setOpenEditDrawer(false);
        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }
        setPassword("");
        setConfirmPassword("");
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" })
    }
  };

  const get_list = async () => {
    const result = await get_customer_listing();

    if (result.code === 200) {
      setListCustomer(result.customer);
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (editData) {
      get_list();
      setId(editData.user_id._id);
    }
  }, [editData]);


  if (editData) {
    return (
      <>
        <Drawer
          className="forms-drawer event-title"
          anchor="right"
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
            setEditData("");
            setPassword("");
            setConfirmPassword("");
          }}
          PaperProps={{
            sx: { width: 350, border: "none", overflow: "hidden" },
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 500, textAlign: "center", mt: 6, mb: 3 }}
          >
            Change Password
          </Typography>
          <form onSubmit={handleSubmit} align="center" >
            <Box
              // component="form"
              sx={{
                "& .MuiTextField-root": { ml: 4, mr: 4, mb: 3, width: "80%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl sx={{ width: '100%' }} variant="outlined">

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="New Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? "ph:eye-fill" : "ri:eye-off-fill"} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: '100%' }} variant="outlined">

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword1 ? "text" : "password"}
                  label="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword1} edge="end">
                          <Icon icon={showPassword1 ? "ph:eye-fill" : "ri:eye-off-fill"} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              {password === confirmPassword && password !== "" &&
                <Box className="">
                  <span id="message" style={{ color: "#008000" }}>Passwords matched!</span>
                </Box>}
              {password !== confirmPassword && password !== "" && confirmPassword !== "" &&
                <Box className="">
                  <span id="message" style={{ color: "#df0101" }}>Passwords do not match!</span>
                </Box>

              }
              <Button type="submit" className="mt-4" variant="contained">
                Update Password
              </Button>
            </Box>
          </form>
        </Drawer>
      </>
    );
  }
}
