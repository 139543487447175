import { invokeApi } from 'src/utils';

export const GetSubCategory = async (page, limit, data) => {
  const requestObj = {
    path: `/api/category/list_category?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


export const AddSubCategory = async (data) => {
  const requestObj = {
    path: `/api/category/add_category`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const EditSubCategory = async (id,data) => {
  const requestObj = {
    path: `/api/category/edit_category/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};


export const DeleteSubCategory = async (id) => {
  const requestObj = {
    path: `/api/category/delete_category/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
