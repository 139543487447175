import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {useSnackbar} from "notistack";
// import { Form, FormikProvider } from "formik";
// import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Button,
  Drawer,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Avatar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Iconify } from "src/components";
import { Box } from "@mui/system";
import { PhotoCamera } from "@mui/icons-material";
import { s3baseUrl } from "src/config/config";
import { EditSubCategory } from "src/DAL/SubCategory";
import { GetCategoryListing } from "src/DAL/GrandCategory";

// ----------------------------------------------------------------------

// EditDrawer.propTypes = {
//   isOpenDrawer: PropTypes.bool,
//   onResetDrawer: PropTypes.func,
//   onOpenDrawer: PropTypes.func,
// };

const activeStatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "In-Active",
  },
];

export default function EditDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editData,
  setEditData,
  drawerCondition,
  setDrawerCondition,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isPending, setIsPending] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [changeImage, setChangeImage] = useState(null);
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [grandCategory, setGrandCategory] = useState(``);
  // const [grandCategoryName, setGrandCategoryName] = useState();
  const [listGrandCategory, setListGrandCategory] = useState();

  const [status, setStatus] = useState(true);

  //   const handleChange = (event) => {
  //     setActive(event.target.value);
  //   };

  const handleSubmit = async (e) => {
    setIsPending(true);
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("grand_category", grandCategory);
    changeImage && formData.append("image", changeImage);
    formData.append("status", status);

    try {
      const result = await EditSubCategory(id, formData);
      if (result.code === 200) {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "success" })
        setOpenEditDrawer(false);
        setIsPending(false);
        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }
        setSelectedImage("");
        setChangeImage(null);
      } else {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "error" })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImage = (e) => {
    setChangeImage(e.target.files[0]);
    const selectedFile = e.target.files[0];

    const imagesArray = URL.createObjectURL(selectedFile);

    setSelectedImage(imagesArray);
  };

  const get_list = async () => {
    const result = await GetCategoryListing();

    if (result.code === 200) {
      setListGrandCategory(result.data.Grand_Category);
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (editData) {
      get_list();
      setId(editData._id);
      setName(editData.name);
      setDescription(editData.description);
      setGrandCategory(editData.grand_category._id);
      // setGrandCategoryName(editData.grand_category.name);
      setStatus(editData.status);
      editData.image && setSelectedImage(s3baseUrl + editData.image);
      setChangeImage(null);
    }
  }, [editData]);

  if (editData) {
    return (
      <>
        {isPending && (
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress />
          </Backdrop>
        )}
        <Drawer
          className="forms-drawer event-title"
          anchor="right"
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
            setEditData("");
            setSelectedImage("");
            setChangeImage(null);
          }}
          PaperProps={{
            sx: { width: 350, border: "none" },
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 500, textAlign: "center", mt: 6, mb: 3 }}
          >
            Edit Child Category
          </Typography>
          <form onSubmit={handleSubmit}  align="center" >
            <Box
              // component="form"
              sx={{
                "& .MuiTextField-root": { ml: 4, mr: 4, mb: 3, width: "80%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                id="outlined-textarea"
                label="Category Name"
                placeholder="Enter Category Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {selectedImage && (
                <Box
                  sx={{ ml: 4, mr: 4, mb: 3, width: "80%", height: "200px" }}
                >
                  <img
                    src={selectedImage}
                    alt="image"
                    height={"100%"}
                    width="100%"
                    style={{objectFit:"contain"}}
                  />
                </Box>
              )}
              <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                <Button
                  variant="outlined"
                  sx={{
                    width: "80%",
                    ml: 4,
                    mr: 4,
                    borderColor: "#C4CDD5",
                    color: "#637381",
                  }}
                  component="label"
                >
                  {!selectedImage && (
                    <>
                      Select Image <PhotoCamera sx={{ ml: 1 }} />
                    </>
                  )}
                  {selectedImage && (
                    <>
                      Change Image <PhotoCamera sx={{ ml: 1 }} />
                    </>
                  )}
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
              </FormControl>

              <TextField
                id="outlined-textarea"
                label="Description"
                // placeholder="Placeholder"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              <TextField
                select
                label="Grand Category"
                SelectProps={{ value: grandCategory }}
                onChange={(e) => setGrandCategory(e.target.value)}
              >
                {listGrandCategory ? (
                  listGrandCategory.map((list, index) => (
                    <MenuItem key={list._id + id} value={list._id} children>
                      {list.name}
                    </MenuItem>
                  ))
                ) : (
                  <div></div>
                )}
              </TextField>

              <TextField
                select
                label="Status"
                SelectProps={{ value: status }}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem key={true} value={true}>
                  Active
                </MenuItem>
                <MenuItem key={false} value={false}>
                  In-Active
                </MenuItem>
              </TextField>
              <Button sx={{ mb: 3 }} type="submit" variant="contained">
                Update Child Category
              </Button>
            </Box>
          </form>
        </Drawer>
      </>
    );
  }
}
