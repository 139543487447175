// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },
  {
    title: "customers",
    path: "/users",
    icon: getIcon("fe:users"),
  },
  {
    title: "Categories",
    path: "/grandcategory",
    icon: getIcon("material-symbols:format-list-bulleted-rounded"),
  },
  {
    title: "Child Categories",
    path: "/subcategory",
    icon: getIcon("ic:baseline-format-indent-increase"),
  },
  {
    title: "Treatments",
    path: "/services",
    icon: getIcon("iconoir:healthcare"),
  },
  {
    title: "Schedule",
    path: "/schedule",
    icon: getIcon("ic:round-schedule"),
  },
  {
    title: "Medical Questions",
    path: "/medicalquestions",
    icon: getIcon("material-symbols:quiz-outline-rounded"),
  },
  {
    title: "Treatment Questions",
    path: "/treatmentquestions",
    icon: getIcon("material-symbols:quiz-outline-rounded"),
  },
  {
    title: "Bookings",
    path: "/bookings",
    icon: getIcon("material-symbols:calendar-month-rounded"),
  },

  /* example: collapsible sidebar routes
    {
      title: "parent",
      path: "/parent",
      icon: getIcon("fe:users"),
    children: [
        {
            title: "child1",
        path: "/parent/child1",
        icon: getIcon("fe:users"),
      },
      {
        title: "child2",
        path: "/parent/child2",
        icon: getIcon("fe:users"),
      },
    ],
  },
  */
];

export default navConfig;
