import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from "notistack";

// import { Form, FormikProvider } from "formik";
// import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Button,
  Drawer,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Avatar,
  Container,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Iconify } from "src/components";
import InputLabel from '@mui/material/InputLabel';
import { Box } from "@mui/system";
import Select from '@mui/material/Select';
import { EditMedicalQuestions } from "src/DAL/MedicalQuestions";
import { QuestionAnswer } from "@mui/icons-material";

// ----------------------------------------------------------------------

// EditDrawer.propTypes = {
//   isOpenDrawer: PropTypes.bool,
//   onResetDrawer: PropTypes.func,
//   onOpenDrawer: PropTypes.func,
// };

// const activeStatus = [
//   {
//     value: true,
//     label: "Active",
//   },
//   {
//     value: false,
//     label: "In-Active",
//   },
// ];

export default function EditDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editData,
  setEditData,
  setIsPending,
  drawerCondition,
  setDrawerCondition,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [newData, setNewData] = useState();
  const [id, setId] = useState();
  const [questionStatement, setQuestionStatement] = useState(editData.question);
  const [questionType, setQuestionType] = useState(editData.question_type);
  const [mcqOptions, setMcqOptions] = useState(["", ""]);
  const [questionOrder, setQuestionOrder] = useState(editData.order);

  //   const handleChange = (event) => {
  //     setActive(event.target.value);
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();

    formData.append("question", questionStatement);
    formData.append("question_type", questionType);
    formData.append("order", questionOrder);
    if (questionType == "MCQ") {

      formData.append("answer", JSON.stringify(mcqOptions));
    }
    if (questionType == "STATEMENT") {

      formData.append("answer", "");
    }
    if (questionType == "YES_OR_NO") {
      let data = ["Yes", "No"]
      formData.append("answer", JSON.stringify(data));

    }
    try {
      const result = await EditMedicalQuestions(id, formData);
      if (result.code === 200) {
        console.log(result);
        setIsPending(false)
        setOpenEditDrawer(false);
        setNewData(editData)
        enqueueSnackbar(result.message, { variant: "success" })

        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }

      } else {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "error" })
        setIsPending(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = (index) => {
    if (mcqOptions.length > 2) {
      console.log("index", index)
      let list = [...mcqOptions]
      list.splice(index, 1)
      setMcqOptions([...list])
    }
  }

  useEffect(() => {
    console.log(editData, "Data Updated")

    if (editData) {
      setId(editData._id);
      setQuestionStatement(editData.question);
      setQuestionType(editData.question_type);
      if (editData.question_type == "MCQ") {
        const ans = JSON.parse(editData.answer)
        console.log(ans, "MCQ Options")
        setMcqOptions([...ans])
      }
    }
  }, [newData]);

  useEffect(() => {
    if (questionType === 'YES_OR_NO')
      setMcqOptions(["YES", "NO"])

  }, [questionType])

  if (editData) {
    return (
      <>
        <Drawer
          className="forms-drawer event-title"
          anchor="right"
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
            setEditData("");
          }}
          PaperProps={{
            sx: { width: 350, border: "none", overflow: "hidden" },
          }}
        >
          <Container style={{overflowY:"scroll"}}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 500, textAlign: "center", mt: 6, mb: 3 }}
            >
              Edit Medical Question
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box
                // component="form"
                sx={{
                  "& .MuiTextField-root": { mb: 3 },
                }}
                noValidate
                autoComplete="off"
              >
                {/* question type*/}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="demo-simple-select-helper-label">Question Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={questionType}
                    label="Question Type"
                    onChange={(e) => {
                      setQuestionType(e.target.value)
                    }}
                  >
                    <MenuItem value="STATEMENT">STATEMENT</MenuItem>
                    <MenuItem value="MCQ">MCQ</MenuItem>
                    <MenuItem value="YES_OR_NO">YES OR NO</MenuItem>
                  </Select>
                </FormControl>

                {/* question statement*/}
                <FormControl fullWidth>
                  <TextField

                    required
                    id="outlined-textarea"
                    label="Question Statement"
                    value={questionStatement}
                    onChange={(e) => setQuestionStatement(e.target.value)}
                    fullWidth
                  />
                </FormControl>

                {
                  questionType === "MCQ"
                  && mcqOptions.length > 0
                  && mcqOptions.map((field, index) => (
                    <FormControl key={index} fullWidth sx={{ mb: 2 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">Option {index + 1}</InputLabel>
                      <OutlinedInput
                        className="option-field"
                        value={field}
                        required
                        onChange={(e) => {
                          mcqOptions[index] = e.target.value
                          setMcqOptions([...mcqOptions])
                        }}
                        id="outlined-adornment-amount"
                        endAdornment={mcqOptions.length > 2 && <InputAdornment position="end" onClick={() => { handleDelete(index) }}><ClearIcon /></InputAdornment>}
                        label="Delete"
                      />
                    </FormControl>
                  ))
                }
                {questionType === "MCQ" && <FormControl fullWidth sx={{ mb: 3, display: "flex", alignItems: "end" }}>
                  <Button
                    sx={{ float: "right", width: 60 }}
                    id="create-btn"
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => setMcqOptions([...mcqOptions, ''])}
                  >
                  </Button>
                </FormControl>}

                {
                  questionType === "YES_OR_NO" && <FormControl fullWidth>
                    <>
                      <TextField width={100} sx={{ mb: 2 }}
                        id="outlined-basic"
                        className="disabled-field"
                        variant="outlined"
                        label="Yes"
                        value={mcqOptions[0]}
                        disabled
                      />
                      <TextField width={100} sx={{ mb: 2 }}
                        id="outlined-basic"
                        variant="outlined"
                        className="disabled-field"
                        label="No"
                        value={mcqOptions[1]}
                        disabled
                      />
                    </>
                  </FormControl>

                }

                {/* question order */}
                <FormControl fullWidth>
                  <TextField

                    required
                    id="outlined-textarea"
                    label="Question Order"
                    value={questionOrder}
                    onChange={(e) => setQuestionOrder(e.target.value)}
                    fullWidth
                  />
                </FormControl>

                <Button sx={{ ml: 10 }} type="submit" variant="contained">
                  Update Question
                </Button>
              </Box>
            </form>
          </Container>
        </Drawer>
      </>
    );
  }
}
