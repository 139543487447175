// @mui
import {
  Grid,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
  Card,
} from "@mui/material";
// components
import { Page } from "src/components";
//hooks
import { useEffect } from "react";
import { useState } from "react";
//-------------------------------------
import { GetSchedule } from "src/DAL/Schedule";
import DayCard from "./components/DayCard";

export default function Schedule() {
  // ----------------------------------------------------------------------
  const [data, setData] = useState();
  const [isPending, setIsPending] = useState(true);
  const [render, setRender] = useState(false);

  //----------------------------------------------------------------
  const getData = async () => {
    const result = await GetSchedule();

    if (result.code === 200) {
      setData(result.data.Day_Schedule);
      setIsPending(false);
      console.log(result.data.Day_Schedule);
    }
  };

  
  useEffect(() => {
    setIsPending(true);
    getData();
  }, [render]);

  return (
    <Page title="Schedule">
      {isPending && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress />
        </Backdrop>
      )}

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          m: 3,
          mt: 1.5,
          pb: 3.5,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ fontWeight: 500, mt: 3, mb: 3 }}>
            Schedule
          </Typography>

          <Grid container spacing={2}>
            {data &&
              data.map((data, i) => (
                <Grid key={i} item xs={12} sm={6} md={3}>
                  <DayCard
                    id={data._id}
                    day={data.day}
                    start_time={data.start_time}
                    end_time={data.end_time}
                    shop_status={data.shop_status}
                    render={render}
                    setRender={setRender}
                    setIsPending={setIsPending}
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
      </Card>
    </Page>
  );
}
