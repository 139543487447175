import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/system";
import { s3baseUrl } from "src/config/config";
import TextareaAutosize from '@mui/base/TextareaAutosize';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Img = styled("img")({
  //   margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Details({ data }) {
  const [open, setOpen] = React.useState(false);
  const [questionList, setQuestionList] = React.useState([])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let strSplit = (str) => {
    let newStr = str.split('T');
    return ( newStr[0] + '  -  ' + newStr[1])
    // console.log(newStr[0],"string0")
    // console.log(newStr[1],"string1")
  }

  //modal start
  const [openModal, setOpenModal] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState("");

  const handleClickOpenModal = (img) => {
    setDisplayImage(img);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setDisplayImage("");
  };

  return (
    <div>
      <Button
        sx={{ p: 0, pt: 0.25 }}
        color={"info"}
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
      >
        View
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: 500, }}
          variant="h5"
        >
          Bookings
        </BootstrapDialogTitle>

        {displayImage !== "" &&
          <BootstrapDialog
            onClose={handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={openModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseModal}>

            </BootstrapDialogTitle>
            <DialogContent>
              <img
                src={`${s3baseUrl + displayImage}`}
                alt="image"
                loading="lazy"
                width={"100%"}
              />
            </DialogContent>
          </BootstrapDialog>}

        <DialogContent >

          <Grid item container rowSpacing={2} spacing={2} sx={{ mt: 2 }}>
            {/* customer & booking details */}
            <Grid item xs={6}>
              {/* customer details */}
              <TextField
                fullWidth
                id="customer_name"
                className="disabled-field"
                label="Customer Name"
                value={data.user.name}
                disabled
                sx={{ mb: 2 }}
              />
              {/* <TextField
                fullWidth
                id="customer_contact"
                className="disabled-field"
                label="Customer Contact"
                value="+923001234567"
                disabled
                sx={{ mb: 2 }}
              /> */}
              <TextField
                fullWidth
                id="customer_email"
                className="disabled-field"
                label="Customer Email"
                value={data.user.email}
                disabled
                sx={{ mb: 2 }}
              />
              {/* booking details */}
              <TextField
                fullWidth
                id="booking_date"
                className="disabled-field"
                label="Booking Date"
                value={data.date}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="booking_start_end_time"
                className="disabled-field"
                label="Booking Time"
                value={data.slots.start_time +' - '+ data.slots.end_time}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="reminder_time"
                className="disabled-field"
                label="Notification Date&Time"
                value={strSplit(data.user_schedule_notification_date_time)}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="booking_status"
                className="disabled-field"
                label="Booking Status"
                value={data.booking_status}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="total_cost"
                className="disabled-field"
                label="Total Cost"
                value={data.price}
                disabled
                sx={{ mb: 2 }}
              />
            </Grid>
            {/* treatment details */}
            <Grid item xs={6}>
              {
                data.service.map((row, index) => (

                  <Accordion key={index} sx={{border:"1px solid #e0e0e0", marginBottom: "5px"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{row.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        fullWidth
                        id="treatment_trending"
                        className="disabled-field"
                        label="Treatment Trending"
                        value={row.top_trending}
                        disabled
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        id="treatment_category"
                        className="disabled-field"
                        label="Category"
                        value={row.category.name}
                        disabled
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        id="treatment_price"
                        className="disabled-field"
                        label="Price"
                        value={row.price}
                        disabled
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        id="treatment_time"
                        className="disabled-field"
                        label="Treatment Time"
                        value={row.time + "min"}
                        disabled
                        sx={{ mb: 2 }}
                      />
                      <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        Description
                      </Typography>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder="Empty"
                        id="treatment_description"
                        className="disabled-field"
                        label="Description"
                        value={row.description}
                        disabled
                        style={{ width: 370, marginBottom: 13 }}
                      />
                      <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        Treatment Questions
                      </Typography>
                      <TextareaAutosize
                        aria-label="Questions"
                        id="treatment_questions"
                        className="disabled-field"
                        label="Questions"
                        // value="demo"
                        value={data.customer_service_questions.map((question_list, question_list_index) => {
                          return (`Question ${question_list_index + 1}: ` + question_list.statement + '\n' + "Answer: " + (question_list.option !== "" && JSON.parse(question_list.option)) + '\n\n');

                        })}
                        disabled
                        style={{ width: 370, marginBottom: 13 }}
                      />
                      {
                        <ImageList sx={{ width: "auto", height: "auto", marginBottom: 0 }} cols={3} rowHeight={140}>
                          {
                            row.image.map((item, index) => (
                              <ImageListItem key={index}>
                                <img
                                  src={`${s3baseUrl + item}`}
                                  alt="cat_image"
                                  loading="lazy"
                                  onClick={() => handleClickOpenModal(item)}
                                  style={{objectFit:"contain"}}
                                />
                              </ImageListItem>
                            ))
                          }
                        </ImageList>
                      }
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </Grid>

          </Grid>




        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            sx={{ m: 1, borderRadius: 0.5 }}
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
