import { s3baseUrl } from "src/config/config";
import { useState } from "react";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  Box,
} from "@mui/material";
// components
import {
  Page,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";

import MoreMenu from "./components/MoreMenu";
import DeleteDialog from "./components/DeleteDialog";
import UserListHead from "./components/UserListHead";
import UserListToolbar from "./components/UserListToolbar";
// mock
import { get_customer_listing, get_search_listing } from "src/DAL/Users";
//
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import StatusMenu from "./components/StatusMenu";
import AddDrawer from "./components/AddDrawer";
import EditDrawer from "./components/EditDrawer";
// ----------------------------------------------------------------------
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Details from "./components/Details";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "phone", label: "Phone No.", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "customerDetail", label: "Details", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   console.log(users)
//   if (users){
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
//   }}

export default function Users() {
  const navigate = useNavigate();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [page, setPage] = useState(0);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [users, setUsers] = useState();
  const [isPending, setIsPending] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  // 
  const [editData, setEditData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [drawerCondition, setDrawerCondition] = useState(true);
  const [displayImage, setDisplayImage] = useState("");

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = (img) => {
    setDisplayImage(img);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDisplayImage("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  // const filteredUsers = applySortFilter(
  //   users,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  const isUserNotFound = users && users.length === 0;

  const handleNav = () => {
    navigate("/users/add-user");
  };

  const get_list = async () => {

    const result = await get_customer_listing(page, rowsPerPage, {
      search: filterName,
    });

    if (result.code === 200) {
      setUsers(result.customer);
      setCount(result.count);
      setIsPending(false);
    }
  };
  useEffect(() => {
    if (filterName !== "")
      setPage(0);
  }, [filterName])
  useEffect(() => {
    get_list();
  }, [page, rowsPerPage, filterName, drawerCondition]);
  return (
    <Page title="Customers">
      <Container maxWidth="xl">
        {isPending && (
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress />
          </Backdrop>
        )}
        <AddDrawer
          openAddDrawer={openAddDrawer}
          setIsPending={setIsPending}
          setOpenAddDrawer={setOpenAddDrawer}
          drawerCondition={drawerCondition}
          setDrawerCondition={setDrawerCondition}
        />
        <EditDrawer
          openEditDrawer={openEditDrawer}
          setOpenEditDrawer={setOpenEditDrawer}
          editData={editData}
          setIsPending={setIsPending}
          setEditData={setEditData}
          setDrawerCondition={setDrawerCondition}
          drawerCondition={drawerCondition}
        />
        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1.5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.5}
            mt={2.5}
            mx={3.5}
          >
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 500, mb: 2.5 }}>
                Customers
              </Typography>
              <Button
                // onClick={toggleDrawer("right", true)}
                onClick={() => setOpenAddDrawer(true)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Customer
              </Button>
            </Box>

            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users && users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                {displayImage !== "" &&
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    width={"auto"}
                    height={"600px"}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

                    </BootstrapDialogTitle>
                    <DialogContent>
                      <img
                        src={`${s3baseUrl + displayImage}`}
                        alt="image"
                        loading="lazy"
                        width={"100%"}
                        height={"auto"}
                        style={{ objectFit: "cover", marginBottom: "20px" }}
                        className="image-spacing"
                      />

                    </DialogContent>

                  </BootstrapDialog>}

                <TableBody>
                  {users &&
                    users.map((row, index) => {
                      const { _id, name, user_id, status, number, image } =
                        row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell>
                            {rowsPerPage * page + index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar alt={name.toUpperCase()} src={s3baseUrl + image} onClick={() => handleClickOpen(image)} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{user_id.email}</TableCell>
                          <TableCell align="left">{number}</TableCell>
                          <TableCell align="left">
                            <StatusMenu
                              get_list={get_list}
                              data={row}
                              setIsPending={setIsPending}
                            />
                          </TableCell>

                          <TableCell>
                            <Typography>
                              <Details data={row} />
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              setOpenEditDrawer={setOpenEditDrawer}
                              data={row}
                              editData={editData}
                              setEditData={setEditData}
                              setOpenDialog={setOpenDialog}
                            />
                            <DeleteDialog
                              setOpenDialog={setOpenDialog}
                              openDialog={openDialog}
                              editData={editData}
                              setIsPending={setIsPending}
                              drawerCondition={drawerCondition}
                              setDrawerCondition={setDrawerCondition}
                            />
                          </TableCell>

                          {/* <TableCell align="right">
                              <UserMoreMenu />
                            </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
