import { invokeApi } from 'src/utils';

export const PostTreatmentQuestions = async (data) => {
  const requestObj = {
    path: `/api/service_question/add_services_question`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const GetTreatmentQuestions = async (search,page, limit ) => {
  const requestObj = {
    path: `/api/service_question/list_services_question?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: {search},
  };
  return invokeApi(requestObj);
};

export const EditTreatmentQuestions = async (id,data) => {
  const requestObj = {
    path: `/api/service_question/edit_services_question/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const DeleteTreatmentQuestions = async (id) => {
  const requestObj = {
    path: `/api/service_question/delete_services_question/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj);
};