import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/system";
import { s3baseUrl } from "src/config/config";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import dayjs from "dayjs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Img = styled("img")({
  //   margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Details({ data }) {
  const [open, setOpen] = React.useState(false);
  const [questionList, setQuestionList] = React.useState([])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //modal start
  const [openModal, setOpenModal] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState("");

  const handleClickOpenModal = (img) => {
    setDisplayImage(img);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setDisplayImage("");
  };



  return (
    <>
      <Button
        sx={{ p: 0, pt: 0.25 }}
        color={"info"}
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
      >
        View
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: 500, }}
          variant="h5"
        >
          Customer
        </BootstrapDialogTitle>

        {displayImage !== "" &&
          <BootstrapDialog
            onClose={handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={openModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseModal}>

            </BootstrapDialogTitle>
            <DialogContent>
              <img
                src={`${s3baseUrl + displayImage}`}
                alt="image"
                loading="lazy"
                width={"100%"}
                className="image-spacing"
              />
            </DialogContent>
          </BootstrapDialog>}

        <DialogContent >

          <Grid item container rowSpacing={2} spacing={2} sx={{ mt: 2 }}>
            {/* customer & booking details */}
            <Grid item xs={6}>
              {/* customer details */}

              {data.image && <Box sx={{ width: 340, height: 128, mb: 3 }}>
                <Img onClick={() => handleClickOpenModal(data.image)} alt={data.name} src={s3baseUrl + data.image} />
              </Box>}

              <TextField
                fullWidth
                id="customer_name"
                className="disabled-field"
                label="Name"
                value={data.name}
                disabled
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                id="customer_email"
                className="disabled-field"
                label="Email"
                value={data.user_id.email}
                disabled
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                id="customer_contact"
                className="disabled-field"
                label="Phone No."
                value={data.number}
                disabled
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                id="age-verification"
                className="disabled-field"
                label="Under 16yrs old?"
                value={data.age=== "true"? "Yes":"NO"}
                disabled
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                id="date_of_date"
                className="disabled-field"
                label="Date Of Birth"
                value={dayjs(data.date_of_birth).format('LL')}
                disabled
                sx={{ mb: 2 }}
              />


              <TextField
                fullWidth
                id="total_cost"
                className="disabled-field"
                label="Occupation"
                value={data.occupation}
                disabled
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="booking_start_end_time"
                className="disabled-field"
                label="Address"
                value={data.address}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="reminder_time"
                className="disabled-field"
                label="Postal Code"
                value={data.post_code}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="booking_status"
                className="disabled-field"
                label="Country"
                value={data.country}
                disabled
                sx={{ mb: 2 }}
              />
            </Grid>

          </Grid>




        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            sx={{ m: 1, borderRadius: 0.5 }}
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
