import PropTypes from "prop-types";
import React, { useState } from "react";
// import { Form, FormikProvider } from "formik";
// import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Button,
  Drawer,
  Typography,
  TextField,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Iconify } from "src/components";
import { Box } from "@mui/system";
import { PhotoCamera } from "@mui/icons-material";
import { AddCategory } from "src/DAL/GrandCategory";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

// AddDrawer.propTypes = {
//   isOpenDrawer: PropTypes.bool,
//   onResetDrawer: PropTypes.func,
//   onOpenDrawer: PropTypes.func,
// };

const activeStatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "In-Active",
  },
];

export default function AddDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  drawerCondition,
  setIsPending,
  setDrawerCondition,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedImage, setSelectedImage] = useState();
  const [changeImage, setChangeImage] = useState();
  // const [id, setId] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);

  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);
    if (changeImage) {
      formData.append("image", changeImage)
    }
    else {
      enqueueSnackbar("Image not found!", { variant: "error" });
      setIsPending(false);
      return 0;
    }
    formData.append("status", status);

    try {
      const result = await AddCategory(formData);
      if (result.code === 200) {
        console.log(result);
        setOpenAddDrawer(false);
        enqueueSnackbar(result.message, { variant: "success" })
        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }
        setName("");
        setDescription("");
        setSelectedImage(null);
        setChangeImage(null)
      } else {
        console.log(result);
        enqueueSnackbar(result.message, { variant: "error" })
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleImage = (e) => {
    setChangeImage(e.target.files[0]);
    const selectedFile = e.target.files[0];
    console.log(selectedFile, "file")
    const imagesArray = URL.createObjectURL(selectedFile);
    console.log("imagearray", imagesArray)
    setSelectedImage(imagesArray);
  };

  return (
    <>
      <Drawer
        className="forms-drawer event-title"
        anchor="right"
        open={openAddDrawer}
        onClose={() => {
          setOpenAddDrawer(false);
          setName("");
          setDescription("");
          setSelectedImage(null);
          setChangeImage(null);
        }}
        PaperProps={{
          sx: { width: 350, border: "none" },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 500, textAlign: "center", mt: 6, mb: 3 }}
        >
          Add Category
        </Typography>
        <form onSubmit={handleSubmit} align="center" >
          <Box
            // component="form"

            sx={{
              "& .MuiTextField-root": { ml: 4, mr: 4, mb: 3, width: "80%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-textarea"
              label="Category Name"
              required
              value={name}
              // placeholder="Enter Category Name"
              onChange={(e) => setName(e.target.value)}
            />
            {selectedImage && (
              <Box sx={{ ml: 4, mr: 4, mb: 3, width: "80%", height: "200px" }}>
                <img
                  src={selectedImage}
                  alt="image"
                  height={"100%"}
                  width="100%"
                  style={{ objectFit: "contain" }}
                />
              </Box>
            )}
            <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
              <Button
                variant="outlined"
                sx={{
                  width: "80%",
                  ml: 4,
                  mr: 4,
                  borderColor: "#C4CDD5",
                  color: "#637381",
                }}
                component="label"
              >
                {!selectedImage && (
                  <>
                    Select Image <PhotoCamera sx={{ ml: 1 }} />
                  </>
                )}
                {selectedImage && (
                  <>
                    Change Image <PhotoCamera sx={{ ml: 1 }} />
                  </>
                )}
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleImage}
                />
              </Button>
            </FormControl>
            <TextField
              id="outlined-textarea"
              label="Description"
              required
              value={description}
              // placeholder="Placeholder"
              multiline
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              // id="filled-select-currency"
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            // helperText="Please select your currency"
            // variant="filled"
            >
              {activeStatus.map((active) => (
                <MenuItem key={active.value} value={active.value}>
                  {active.label}
                </MenuItem>
              ))}
            </TextField>


            <Button
              sx={{ align: "center" }}
              type="submit"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Category
            </Button>

          </Box>
        </form>
      </Drawer>
    </>
  );
}
