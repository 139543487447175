import { invokeApi } from 'src/utils';

export const login = async (data) => {
  const requestObj = {
    path: `/api/app_api/login`,
    method: 'POST',
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const register = async (data) => {
  const requestObj = {
    path: `/api/saloon/signup_saloon`,
    method: 'POST',
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const logout = async (data) => {
  const requestObj = {
    path: `/api/saloon/signup_saloon`,
    method: 'POST',
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};