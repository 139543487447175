import { invokeApi } from 'src/utils';

export const GetCategoryListing = async (page, limit, data) => {
  const requestObj = {
    path: `/api/grand_category/list_grand_category_for_admin?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


export const AddCategory = async (data) => {
  const requestObj = {
    path: `/api/grand_category/add_grand_category`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};



export const EditCategory = async (id,data) => {
  const requestObj = {
    path: `/api/grand_category/edit_grand_category/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const DeleteCategory = async (id) => {
  const requestObj = {
    path: `/api/grand_category/delete_grand_category/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    // postData: data, 
  };
  return invokeApi(requestObj);
};
