import { invokeApi } from 'src/utils';

export const GetSchedule = async () => {
    const requestObj = {
        path: `/api/day_schedule/list_day_schdule`,
        method: 'GET',
        headers: {
            'x-sh-auth': localStorage.getItem('token')
        },
        // postData: data,
    };
    return invokeApi(requestObj);
};

export const EditSchedule = async (id,data) => {
  const requestObj = {
    path: `/api/day_schedule/edit_day_schedule/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};


// export const AddNewService = async (data) => {
    //   const requestObj = {
//     path: `/api/services/add_services`,
//     method: 'POST',
//     headers: {
//       'x-sh-auth': localStorage.getItem('token')
//     },
//     postData: data, 
//   };
//   return invokeApi(requestObj);
// };

// export const UploadServiceImage = async (data) => {
//   const requestObj = {
//     path: `/api/app_api/upload_image_s3`,
//     method: 'POST',
//     headers: {
//       'x-sh-auth': localStorage.getItem('token')
//     },
//     postData: data, 
//   };
//   return invokeApi(requestObj);
// };


// export const DeleteService = async (id) => {
//   const requestObj = {
//     path: `/api/services/delete_services/${id}`,
//     method: 'DELETE',
//     headers: {
//       'x-sh-auth': localStorage.getItem('token')
//     },
//     // postData: data,
//   };
//   return invokeApi(requestObj);
// };
