import React, { useState } from "react";
// import { Form, FormikProvider } from "formik";
// import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Button,
  Drawer,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import { Iconify } from "src/components";
import { Box } from "@mui/system";
import { PhotoCamera } from "@mui/icons-material";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
// ----------------------------------------------------------------------
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// ----------------------------------------------------------------------
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { add_customer_by_admin } from "src/DAL/Users";
// ----------------------------------------------------------------------

import { Icon } from "@iconify/react";

// AddDrawer.propTypes = {
//   isOpenDrawer: PropTypes.bool,
//   onResetDrawer: PropTypes.func,
//   onOpenDrawer: PropTypes.func,
// };

const activeStatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "In-Active",
  },
];

export default function AddDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  drawerCondition,
  setIsPending,
  setDrawerCondition,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedImage, setSelectedImage] = useState();
  const [changeImage, setChangeImage] = useState();
  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [ageVerification, setAgeVerification] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("Ireland");

  const [occupation, setOccupation] = useState("");
  const [status, setStatus] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowPassword1 = () => setShowPassword1((show) => !show);

  const handleChangeDate = (newValue) => {
    setDateOfBirth(newValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  // };

  const handleChange = (event) => {
    setAgeVerification(event.target.checked);
  };

  // const emailHandle = (e) => {
  //   setEmail(e.target.value)
  //   let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   if (e.target.value.match(mailformat))
  //   { 
  //     e.target.value.focus();      
  //     return true;
  //   }
  //   else {
  //     enqueueSnackbar("Not a valid email", { variant: "error" });
  //     return false
  //   }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();

    let letters = /^[A-Za-z\s]*$/;
    if (name.match(letters)) {
      formData.append("name", name);
    }
    else {
      enqueueSnackbar("Name contains only letters!", { variant: "error" });
      setIsPending(false);
      return false;
    }
    if (password === confirmPassword && password !== "") {
      if (password.length > 5) {
        formData.append("password", password);
      }
      else {
        enqueueSnackbar("Password must be 6 characters long!", { variant: "error" });
        setIsPending(false);
        return false;
      }
    }

    formData.append("confirm_password", confirmPassword);
    formData.append("status", status);
    formData.append("date_of_birth", dateOfBirth);
    formData.append("post_code", postalCode);
    formData.append("number", phone);
    formData.append("country", country);
    formData.append("occupation", occupation);
    formData.append("address", address);
    formData.append("age", ageVerification);
    // if (changeImage) {
    //   formData.append("image", changeImage)
    //   console.log(changeImage, "imageee")
    // }
    // else {
    //   console.log(changeImage, "imageeeelse")
    //   enqueueSnackbar("Image not found!", { variant: "error" });
    //   setIsPending(false);
    //   return 0;
    // }
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      formData.append("email", email);
    }
    else {
      enqueueSnackbar("You have entered an invalid email address!", { variant: "error" });
      setIsPending(false);
      return 0;
    }


    try {
      console.log([...formData], "formmm")
      const result = await add_customer_by_admin(formData);
      if (result.code === 200) {
        console.log(result);
        setOpenAddDrawer(false);
        setIsPending(false);
        enqueueSnackbar("Customer added!", { variant: "success" })
        {
          drawerCondition
            ? setDrawerCondition(false)
            : setDrawerCondition(true);
        }
        setName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setStatus("");
        setDateOfBirth(dayjs());
        setPostalCode(null);
        setPhone(null);
        setCountry(null);
        setOccupation(null);
        setAddress(null);
        setAgeVerification(false)
        setSelectedImage(null);
        setChangeImage(null);
      } else {
        console.log(result);
        setIsPending(false);
        enqueueSnackbar(result.message, { variant: "error" })
      }
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };
  const handleImage = (e) => {
    setChangeImage(e.target.files[0]);
    console.log(changeImage, "uploadimage")
    const selectedFile = e.target.files[0];
    const imagesArray = URL.createObjectURL(selectedFile);
    setSelectedImage(imagesArray);
  };

  return (
    <>
      <Drawer
        className="forms-drawer event-title"
        anchor="right"
        open={openAddDrawer}
        onClose={() => {
          setOpenAddDrawer(false);
          setName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setStatus("");
          setDateOfBirth(dayjs());
          setPostalCode("");
          setPhone(null);
          setCountry("");
          setOccupation("");
          setAddress("");
          setAgeVerification(false)
          setSelectedImage(null);
          setChangeImage(null)
        }}
        PaperProps={{
          sx: { width: 350, border: "none" },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 500, textAlign: "center", mt: 6, mb: 3 }}
        >
          Add Customer
        </Typography>
        <form onSubmit={handleSubmit} align="center">
          <Box
            // component="form"

            sx={{
              "& .MuiTextField-root": { ml: 4, mr: 4, mb: 3, width: "80%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              className="outlined-textarea"
              label="Customer Name"
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/* {selectedImage && (
              <Box sx={{ ml: 4, mr: 4, mb: 3, width: "80%", height: "120px" }}>
                <img
                  src={selectedImage}
                  alt="image"
                  height={"100%"}
                  width="100%"
                />
              </Box>
            )}
            <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
              <Button
                variant="outlined"
                sx={{
                  width: "80%",
                  ml: 4,
                  mr: 4,
                  borderColor: "#C4CDD5",
                  color: "#637381",
                }}
                component="label"
              >
                {!selectedImage && (
                  <>
                    Select Image <PhotoCamera sx={{ ml: 1 }} />
                  </>
                )}
                {selectedImage && (
                  <>
                    Change Image <PhotoCamera sx={{ ml: 1 }} />
                  </>
                )}
                <input
                  hidden

                  accept="image/*"
                  type="file"
                  onChange={handleImage}
                />
              </Button>
            </FormControl> */}

            <TextField
              className="outlined-textarea"
              label="Email"
              type="email"
              required
              autoComplete={"false"}
              value={email}
              // placeholder="Placeholder"
              onChange={(e) => setEmail(e.target.value)}
            />

            <PhoneInput
              className="outlined-textarea phone_input"
              country={'ie'}
              enableSearch={true}
              disableSearchIcon={true}
              placeholder="Phone No."
              value={phone}
              onChange={(phone,country) => {setPhone( phone ); (setCountry(country.name))}}
              style={{ margin: "0 33px 24px", width: "80%", height: "50px", borderRadius: "8px", border: "1px solid #ccc" }}
            />

            <FormControl sx={{ width: '100%' }} variant="outlined">

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label="New Password"
                // inputProps={{ minlength: 6 }}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? "ph:eye-fill" : "ri:eye-off-fill"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl sx={{ width: '100%' }} variant="outlined">

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword1 ? "text" : "password"}
                label="Confirm Password"
                // inputProps={{ minlength: 6 }}
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword1} edge="end">
                        <Icon icon={showPassword1 ? "ph:eye-fill" : "ri:eye-off-fill"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            {password === confirmPassword && password !== "" &&
              <div className="">
                <p id="message" style={{ color: "#008000" }}>Passwords matched!</p>
              </div>}
            {password !== confirmPassword && password !== "" && confirmPassword !== "" &&
              <div className="">
                <p id="message" style={{ color: "#df0101" }}>Passwords do not match!</p>
              </div>
            }

            <FormGroup style={{ margin: "0 36px 18px" }}>
              <FormControlLabel control={<Checkbox checked={ageVerification} onChange={handleChange} />} label="Under 16yrs old?" />
            </FormGroup>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Date Of Birth"
                  inputFormat="MM/DD/YYYY"
                  value={dateOfBirth}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>

            <TextField
              className="outlined-textarea"
              label="Address"
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />

            <TextField
              className="outlined-textarea"
              label="Postal Code"
              required
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />

            <TextField
              className="outlined-textarea"
              label="Country"
              required
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />

            <TextField
              className="outlined-textarea"
              label="Occupation"
              required
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
            />

            <TextField
              // id="filled-select-currency"
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            // helperText="Please select your currency"
            // variant="filled"
            >
              {activeStatus.map((active) => (
                <MenuItem key={active.value} value={active.value}>
                  {active.label}
                </MenuItem>
              ))}
            </TextField>


            <Button
              sx={{ align: "center" }}
              type="submit"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Customer
            </Button>

          </Box>
        </form>
      </Drawer>
    </>
  );
}
