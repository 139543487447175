import { invokeApi } from 'src/utils';

export const GetBookingListing = async (search, page, limit) => {
  const requestObj = {
    path: `/api/booking/booking_listing?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
  };
  
  if (search.trim() != '') {
    requestObj["postData"] = { search: search }
  }
  console.log(search, "search srasrch")
  return invokeApi(requestObj);
};

export const edit_booking_status = async (data) => {
  const requestObj = {
    path: `/api/booking/change_booking_status`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};