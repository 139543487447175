import { Checkbox, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import * as React from "react";

import { useEffect, useState } from "react";

import { EditServiceAPI } from "src/DAL/Services";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function TopTrending({ data, setIsPending }) {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("");
  const [status, setStatus] = useState(true);
  const [topTrending, setTopTrending] = useState(true);
  const [category, setCategory] = useState("");
  const [newPrice, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);

  const handleTopTrending = async () => {
    setIsPending(true);
    let result = await EditServiceAPI(id, {
      top_trending: topTrending === true ? false : true,
      title: title,
      description: description,
      category: category,
      price: newPrice,
      time: time,
      order: order,
      status: status,
      image: image,
      // service_questions: [
      //   "62de4108e0da962898235be4",
      //   "62de410de0da962898235be5",
      // ],
      // // medical_questions: [
      //   "63846a109b6f3e0bb8c2082f",
      //   "6385ebabddc0f7201c4071e6",
      // ],
    });
    if (result.code === 400) {
      setIsPending(false);
      alert(result.message);
    } else {
      setIsPending(false);
      topTrending === true ? setTopTrending(false) : setTopTrending(true);
    }
  };

  useEffect(() => {
    if (data) {
      setId(data._id);
      setOrder(data.order);
      setTitle(data.title);
      setCategory(data.category._id);
      setStatus(data.status);
      setDescription(data.description);
      setTopTrending(data.top_trending);
      setPrice(data.price.toString());
      setTime(data.time);
      setImage(data.image);
    }
  }, [data]);

  if (data) {
    return (
      <div>
        <LightTooltip title="Top Trending">
          <Checkbox
            checked={topTrending}
            onChange={handleTopTrending}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
          />
        </LightTooltip>
      </div>
    );
  }
}
