import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState } from 'react';

const CloseDialog = ({ open, setOpen, title, ActionButton1, ActionButton2, handleActionButton1, handleActionButton2 }) => {
  
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      {/* <DialogTitle>Subscribe</DialogTitle> */}
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleActionButton1}>{ActionButton1}</Button>
        <Button onClick={handleActionButton2}>{ActionButton2}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CloseDialog;