import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { s3baseUrl } from "src/config/config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Img = styled("img")({
  //   margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Details({ data }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //modal start
  const [openModal, setOpenModal] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState("");

  const handleClickOpenModal = (img) => {
    setDisplayImage(img);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setDisplayImage("");
  };

  return (
    <div>
      <Button
        sx={{ p: 0, pt: 0.25 }}
        color={"info"}
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
      >
        View
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: 500, }}
          variant="h5"
        >
          {data.name}
        </BootstrapDialogTitle>

        {displayImage !== "" &&
          <BootstrapDialog
            onClose={handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={openModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseModal}>

            </BootstrapDialogTitle>
            <DialogContent>
              <img
                src={`${s3baseUrl + displayImage}`}
                alt="image"
                loading="lazy"
                width={"100%"}
                className="image-spacing"
              />

            </DialogContent>

          </BootstrapDialog>}

        <DialogContent >
          <Grid container sx={{ p: 1 }}>
            <Grid item>
              <Box sx={{ width: 340, height: 128, mb: 3 }}>
                <Img onClick={() => handleClickOpenModal(data.image)} alt={data.name} src={s3baseUrl + data.image} />
              </Box>
            </Grid>
            <Grid item xs container direction="column" spacing={3}>
              <TextField
                sx={{ my: 3 }}
                id="grandcategory"
                className="disabled-field"
                label="Grand-Category"
                value={data.name}
                disabled
              ></TextField>
              <TextField
                sx={{ mb: 3 }}
                id="status"
                label="Status"
                className="disabled-field"
                value={data.status === true ? "Active" : "In-Active"}
                disabled
              ></TextField>
            </Grid>
            <TextField
              id="Description"
              className="disabled-field"
              label="Description"
              multiline
              fullWidth
              maxRows={8}
              value={data.description}
              disabled
            />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            sx={{ m: 1, borderRadius: 0.5 }}
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
