import { filter } from "lodash";
import { s3baseUrl } from "src/config/config";
import { sentenceCase } from "change-case";
import { useState } from "react";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import ListHead from "./components/ListHead";
import ListToolbar from "./components/ListToolbar";
import MoreMenu from "./components/MoreMenu";
import Tooltip from '@mui/material/Tooltip';
// mock
import { GetSubCategory } from "src/DAL/SubCategory";
//
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CleaningServices } from "@mui/icons-material";
import users from "src/_mock/user";
import { LoadingButton } from "@mui/lab";
import AddDrawer from "./components/AddDrawer";
import EditDrawer from "./components/EditDrawer";
import DeleteDialog from "./components/DeleteDialog";
import ErrorDialog from "./components/ErrorDialog";
import Details from "./components/Details";
//
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "grand_category", label: "Grand Category", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "details", label: "Details", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   console.log(users)
//   if (users){
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
//   }}

export default function SubCategory() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [subCategories, setSubCategories] = useState();
  const [isPending, setIsPending] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [drawerState, setDrawerState] = useState(false);
  const [editData, setEditData] = useState();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [drawerCondition, setDrawerCondition] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [serverErrorDialog, setServerErrorDialog] = useState(false);
  const [displayImage, setDisplayImage] = useState("");

  // modal start
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = (img) => {
    setDisplayImage(img);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDisplayImage("");
  };
  // modal end
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subCategories.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleEditDrawer = () => {
    setEditDrawerState(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subCategories.length) : 0;

  // const filteredUsers = applySortFilter(
  //   users,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  const isUserNotFound = subCategories && subCategories.length === 0;

  const handleNav = () => {
    navigate("/users/add-user");
  };

  const get_list = async () => {
    const result = await GetSubCategory(page, rowsPerPage, {
      search: filterName,
    });

    if (result.code === 200) {
      setSubCategories(result.data.Category);
      setCount(result.data.count);
      setIsPending(false);
    }
    if (result.code !== 200) {
      setIsPending(false)
      setServerErrorDialog(true)
    }
  };
  useEffect(() => {
    get_list();
  }, [page, rowsPerPage, filterName, drawerCondition]);
  useEffect(() => {
    if (filterName !== "")
      setPage(0);
  }, [filterName])
  return (
    <Page title="Child Categories">
      <Container maxWidth="xl">
        {isPending && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress />
          </Backdrop>
        )}
        <AddDrawer
          openAddDrawer={openAddDrawer}
          setOpenAddDrawer={setOpenAddDrawer}
          setIsPending={setIsPending}
          drawerCondition={drawerCondition}
          setDrawerCondition={setDrawerCondition}
        />
        <EditDrawer
          openEditDrawer={openEditDrawer}
          setOpenEditDrawer={setOpenEditDrawer}
          editData={editData}
          setEditData={setEditData}
          setDrawerCondition={setDrawerCondition}
          drawerCondition={drawerCondition}
        />
        <ErrorDialog
          serverErrorDialog={serverErrorDialog}
          settServerErrorDialog={setServerErrorDialog}
        />
        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1.5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.5}
            mt={2.5}
            mx={3.5}
          >
            <div>
              <Typography variant="h4" sx={{ fontWeight: 500, mb: 2.5 }}>
                Child Categories
              </Typography>
              <Button
                onClick={() => setOpenAddDrawer(true)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Child Category
              </Button>
            </div>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={subCategories && subCategories.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {displayImage !== "" &&
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

                    </BootstrapDialogTitle>
                    <DialogContent>
                      <img
                        src={`${s3baseUrl + displayImage}`}
                        alt="image"
                        loading="lazy"
                        width={"100%"}
                        className="image-spacing"
                      />

                    </DialogContent>

                  </BootstrapDialog>}
                <TableBody>
                  {subCategories &&
                    subCategories
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const {
                          _id,
                          name,
                          status,
                          grand_category,
                          description,
                          image,
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <>
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>
                                {rowsPerPage * page + index + 1}
                              </TableCell>

                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Avatar onClick={() => handleClickOpen(image)} alt={name} src={s3baseUrl + image} />
                              </TableCell>
                              <TableCell align="left">
                                {grand_category.name && grand_category.name}
                              </TableCell>
                              <TableCell>
                                <Tooltip title={description} placement="bottom">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      width: "200px",
                                      display: "block",
                                      overflow: "hidden",
                                    }}
                                  >

                                    {description}

                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === false && "error") || "success"
                                  }
                                >
                                  {status === true ? "Active" : "In-Active"}
                                </Label>
                              </TableCell>
                              <TableCell>
                                <Details
                                  data={row}
                                />
                              </TableCell>

                              <TableCell align="right">
                                <MoreMenu
                                  setOpenEditDrawer={setOpenEditDrawer}
                                  data={row}
                                  editData={editData}
                                  setEditData={setEditData}
                                  setOpenDialog={setOpenDialog}
                                />
                                <DeleteDialog
                                  setOpenDialog={setOpenDialog}
                                  openDialog={openDialog}
                                  editData={editData}
                                  setIsPending={setIsPending}
                                  drawerCondition={drawerCondition}
                                  setDrawerCondition={setDrawerCondition}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
