import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Card, Grid, TextField } from "@mui/material";
import { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { EditSchedule } from "src/DAL/Schedule";
import { TimePicker } from "@mui/x-date-pickers";

export default function EditScheduleDialog({
  setOpenEditDialog,
  openEditDialog,
  start_time,
  end_time,
  myShop_Status,
  day,
  id,
  render,
  setRender,
  setIsPending,
}) {
  const [mystart_time, setMystart_time] = useState(start_time);
  const [myend_time, setMyend_time] = useState(end_time);

  const handleStartTimeChange = (newValue) => {
    const hours = dayjs(newValue).get("Hours");
    const minutes = dayjs(newValue).get("Minute");
    const timeSTR = `${hours}:${minutes}`;
    const time = dayjs(timeSTR, "hh:mm").format("HH:mm");
    setMystart_time(time);
  };

  const handleEndTimeChange = (newValue) => {
    const hours = dayjs(newValue).get("hour");
    const minutes = dayjs(newValue).get("minute");
    const timeSTR = `${hours}:${minutes}`;
    const time = dayjs(timeSTR, "hh:mm").format("HH:mm");
    setMyend_time(time);
  };
  
  const handleEditSchedule = async (e) => {
    e.preventDefault();
    if(mystart_time==myend_time){
      window.alert("Invalid time selection!")
    }
    else{
    setOpenEditDialog(false);
    setIsPending(true);
    console.log(mystart_time);
    const formData = new FormData();
    formData.append("start_time", mystart_time);
    formData.append("end_time", myend_time);
    formData.append("shop_status", myShop_Status);

    await EditSchedule(id, formData);

    {
      render === true ? setRender(false) : setRender(true);
    }}
  };

  const handleClose = () => {
    setOpenEditDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openEditDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{day}</DialogTitle>
        <form onSubmit={handleEditSchedule}>
          <DialogContent>
            <Grid
              container
              rowSpacing={1}
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Card
                  item
                  xs={6}
                  sx={{
                    borderRadius: 0,
                    m: 0,
                    width: "100%",
                    boxShadow: 0,
                  }}
                >
                  <TimePicker
                    label="From"
                    value={dayjs(mystart_time, "HH:mm")}
                    onChange={handleStartTimeChange}
                    renderInput={(params) => (
                      <TextField fullWidth sx={{ mt: 1 }} {...params} />
                    )}
                  />

                  <TimePicker
                    label="To"
                    validate
                    value={dayjs(myend_time, "HH:mm")}
                    // disabled={myShop_Status.toUpperCase() === "OPEN" ? false : true}
                    onChange={handleEndTimeChange}
                    renderInput={(params) => (
                      <TextField sx={{ mt: 3 }} fullWidth {...params} />
                    )}
                  />
                </Card>
              </LocalizationProvider>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ mx: 1 }}>
            <Button
              onClick={handleClose}
              variant="outlinedInherit"
              sx={{
                borderColor: "#C4CDD5",
                color: "#637381",
                borderRadius: 0.5,
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              // onClick={handleEditSchedule}
              variant="contained"
              sx={{
                borderColor: "#C4CDD5",
                color: "#fff",
                borderRadius: 0.5,
              }}
              autoFocus
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
