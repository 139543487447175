// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Backdrop, CircularProgress } from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { Page } from "src/components";
//hooks
import { useAppContext } from "src/hooks";
import { useEffect } from "react";
import { useState } from "react";
import { GetDashboardData } from "src/DAL/Dashboard";


// ----------------------------------------------------------------------
export default function Dashboard() {
  
  const [dashboardData, setData] = useState();
  const [isPending, setIsPending] = useState(true);
  var DUMMY_DATA = [];
  
    const getData = async () => {
      const result = await GetDashboardData();
      
      if (result.code === 200) {
        setData(result.Saloon);
        setIsPending(false);
      }
    };
    useEffect(() => {
      getData();
    }, []);

  if(!isPending){
   DUMMY_DATA = [ 
    {
      color: "#50ae55",
    title: "Customers",
    count: dashboardData.total_saloon_customer,
    icon: "fe:users",
  },
  {
    color: "#f98c25",
    title: "Bookings",
    count: dashboardData.total_saloon_booking,
    icon: "icon-park-outline:sales-report",
  },
  {
    color: "#4154b3",
    title: "Treatments",
    count: dashboardData.total_saloon_services,
    icon: "clarity:cloud-traffic-line",
  },
  {
    color: "#A12F83",
    title: "Categories",
    count: dashboardData.total_saloon_grand_category,
    icon: "material-symbols:format-list-bulleted-rounded",
  },
  {
    color: "#73573F",
    title: "Sub-Categories",
    count: dashboardData.total_saloon_category,
    icon: "ic:baseline-format-indent-increase",
  },
];
  }
  // const theme = useTheme();
  // const { _get_user_profile } = useAppContext();
  // useEffect(() => {}, []);
  

  return (
    <Page title="Dashboard">
       {isPending && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress />
          </Backdrop>
        )}
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ fontWeight: 600, mt: 4, mb:4 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {dashboardData && DUMMY_DATA.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={4}>
              <SummaryCard
                bcolor={item.color}
                title={item.title}
                count={item.count}
                icon={item.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
