import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { s3baseUrl } from "src/config/config";
import "./index.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const Img = styled("img")({
//   //   margin: "auto",
//   display: "block",
//   maxWidth: "100%",
//   maxHeight: "100%",
// });

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ServiceDetails({ data }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //modal start
  const [openModal, setOpenModal] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState("");

  const handleClickOpenModal = (img) => {
    setDisplayImage(img);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setDisplayImage("");
  };

  return (
    <div>
      <Button
        sx={{  p: 0, pt: 0.25 }}
        color={"info"}
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
      >
        View
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: 500 }}
          variant="h5"
        >
          {data.title}
        </BootstrapDialogTitle>

        {displayImage !== "" &&
          <BootstrapDialog
            onClose={handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={openModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseModal}>

            </BootstrapDialogTitle>
            <DialogContent>
              <img
                src={`${s3baseUrl + displayImage}`}
                alt="image"
                loading="lazy"
                width={"100%"}
                className="image-spacing"
              />
            </DialogContent>
          </BootstrapDialog>}

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <TextField
                sx={{ my: 2, pointerEvents: "none" }}
                id="category"
                width="2"
                label="Category"
                value={data.category.name}
                fullWidth
              ></TextField>
              <TextField
                sx={{ mb: 2, pointerEvents: "none" }}
                id="grandcategory"
                label="Grand-Category"
                value={data.grand_category.name}
                fullWidth
              ></TextField>
              <TextField
                sx={{ mb: 2, pointerEvents: "none" }}
                id="status"
                label="Status"
                value={data.status === true ? "Active" : "In-Active"}
                fullWidth
              ></TextField>
              <Grid item xs container direction="row">
                {/* <Grid> */}
                <TextField
                  id="price"
                  sx={{ pr: 0.5, width: "50%", pointerEvents: "none" }}
                  label="Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={data.price}
                ></TextField>
                <TextField
                  sx={{ pl: 0.5, width: "50%", pointerEvents: "none" }}
                  id="Duration"
                  label="Duration (minutes)"
                  value={data.time}
                ></TextField>
              </Grid>
              <TextField
                sx={{ marginTop: "15px", width: "100%", pointerEvents: "none" }}
                id="treatment-question-list"
                label="Treatment Questions"
                multiline
                value={data.service_questions.map((question, index) => {
                  return (
                    `Question ${index + 1}: ` + question.statement + '\n' + "Option: " + (question.option !== "" && JSON.parse(question.option)) + '\n \n'
                  )
                })}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Description"
                label="Description"
                className="disabled-field"
                multiline
                fullWidth
                rows={11}
                sx={{ mt: 2 }}
                value={data.description}
                disabled
              />
              <div className="images" style={{ marginTop: "15px" }}>
                {data.image &&
                  data.image.map((image, index) => {
                    return (
                      <div key={index} className="image">
                        <img onClick={() => handleClickOpenModal(image)} src={s3baseUrl + image} height="150" alt="upload" />
                      </div>
                    );
                  })}
              </div>
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            sx={{ m: 1, borderRadius: 0.5 }}
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
