import { invokeApi } from 'src/utils';

export const GetServices = async (page, limit, data) => {
  const requestObj = {
    path: `/api/services/list_service?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


export const AddNewService = async (data) => {
  const requestObj = {
    path: `/api/services/add_services`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};
export const UploadServiceImage = async (data) => {
  const requestObj = {
    path: `/api/app_api/upload_image_s3`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const EditServiceAPI = async (id,data) => {
  const requestObj = {
    path: `/api/services/edit_service/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    postData: data, 
  };
  console.log(data,"datadatadata")
  return invokeApi(requestObj);
};


export const DeleteService = async (id) => {
  const requestObj = {
    path: `/api/services/delete_services/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
